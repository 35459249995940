import { AxiosInstance } from 'axios';
import { createApiClient } from './base-api';
import store from './store';
import { uiOperations } from '../models/ui';
import { ID as AuthenticationErrorModalID } from '../components/authentication-error-modal/authentication-error-modal';

class MojoApiFactory {
  static apiUrl: string;
  static programId: string;
  static authHeaders: Record<string, string>;

  private static getApiInstance(): AxiosInstance {
    const api = createApiClient();

    // Redirect to login on any unauth'd response
    api.interceptors.response.use(undefined, (err) => {
      if (
        err.response.status === 401 &&
        !err.response.config.ignoreUnauthedRedirect
      ) {
        console.error(err);
        store.dispatch(uiOperations.addOverlay(AuthenticationErrorModalID));
      }

      return Promise.reject(err);
    });

    api.defaults.headers.common = this.authHeaders;

    return api;
  }

  static get v2() {
    const api = this.getApiInstance();
    api.defaults.baseURL = `${this.apiUrl}/v2/programs/${this.programId}`;
    return api;
  }

  static get v3() {
    const api = this.getApiInstance();
    api.defaults.baseURL = `${this.apiUrl}/v3/programs/${this.programId}`;
    return api;
  }

  static get notifications() {
    const api = this.getApiInstance();
    api.defaults.baseURL = `${this.apiUrl}/notif/v1/programs/${this.programId}`;
    return api;
  }

  static get base() {
    return this.getApiInstance();
  }
}

export default MojoApiFactory;
