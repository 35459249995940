import { AxiosPromise } from 'axios';
import MojoApiFactory from '../config/api-factory';

type UploadAttachmentsParams = {
  url: string;
  programId: string;
};

type UploadAttachmentsResponse = {
  data: {
    hosted_url: string;
  };
};

/**
 * Uploads an attachment to the server and returns the hosted URL.
 *
 * @param {UploadAttachmentsParams} params - The parameters for the upload.
 * @param {string} params.url - The URL of the attachment to be uploaded.
 * @param {string} params.programId - The ID of the program associated with the upload.
 *
 * @returns {Promise<string>} A promise that resolves to the hosted URL of the uploaded attachment.
 *
 * @throws {Error} Throws an error if the upload fails.
 */
export const uploadAttachments = async ({
  url,
  programId,
}: UploadAttachmentsParams): Promise<string> => {
  return MojoApiFactory.v2
    .post(`/uploads/host_image`, {
      url,
      program_id: programId,
    })
    .then((response) => response.data.hosted_url);
};
