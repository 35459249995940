import cx from 'classnames';
import styles from './../notification-center.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@socialchorus/shared-ui-components';
import { useContext } from 'react';
import { NotificationContext } from '../context';

export type ArchivePartialConfirmationMobileOverlayProps = {
  onConfirm: () => void;
  archivableCount: number;
  totalCount: number;
};

export const ArchivePartialConfirmationMobileOverlay = ({
  onConfirm,
  archivableCount,
  totalCount,
}: ArchivePartialConfirmationMobileOverlayProps) => {
  const { t } = useTranslation();
  const {
    mobileOverlay: { close },
  } = useContext(NotificationContext);

  const handleContinue = () => {
    onConfirm();
    close();
  };

  return (
    <div className={styles.archivePartialContainer}>
      <div className={cx(styles.archive_header, 'text-body-1 text-black-90')}>
        {t('notification_center.archive_confirm_modal.title')}
      </div>
      <div className={styles.archive_body}>
        <div
          className={cx(
            styles.description,
            'text-body-2 font-semibold text-black-90'
          )}
        >
          {t('notification_center.archive_confirm_modal.description', {
            count: archivableCount,
            total: totalCount,
          })}
        </div>
        <div className={cx(styles.explaination, 'text-body-2 text-black-90')}>
          {t('notification_center.archive_confirm_modal.explaination')}
        </div>
      </div>
      <div className={styles.archive_footer}>
        <Button variant="outline" label={t('common.cancel')} onClick={close} />
        <Button
          variant="primary"
          label={t('common.continue')}
          onClick={handleContinue}
        />
      </div>
    </div>
  );
};
