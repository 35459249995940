import api from '../config/api';

class MessageService {
  id: string;
  trackingId: string | null;

  constructor(id: string, trackingId: string | null = null) {
    this.id = id;
    this.trackingId = trackingId;
  }

  private withTrackingParams(baseParams: Record<string, any> = {}) {
    const trackingParams = this.trackingId
      ? { tracking_id: this.trackingId }
      : {};
    return { ...baseParams, ...trackingParams };
  }

  fetch = () =>
    api.get(`/messages/${this.id}/`, {
      params: this.withTrackingParams(),
    });
  view = () =>
    api.post(`/messages/${this.id}/view/`, this.withTrackingParams());
  acknowledge = () =>
    api.post(`/messages/${this.id}/acknowledge/`, this.withTrackingParams());
}

export default MessageService;
