import MessageFrame from '../../../components/messages/message-frame';
import cx from 'classnames';
import { AcknowledgementFooter } from '../../../components/messages/acknowledgement-footer';
import './message-detail.scss';
import { BackButton } from '../../../components/v2/content-detail/back-button';
import { useHistory } from 'react-router';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';
import { Message } from '../../../models/messages/types';
import { Avatar } from '@socialchorus/shared-ui-components';
import { localizeDate } from '../../../lib/date-formatter';

const MessageDetail = ({ message }: { message: Message }) => {
  const history = useHistory();
  const backButtonDisabled = useFeatureFlag(
    Feature.CONTENT_BACK_BUTTON_REMOVED
  );

  const requires_acknowledgement = !!message?.acknowledgement;

  return (
    <>
      {!backButtonDisabled && (
        <BackButton navigateBack={() => history.goBack()} />
      )}
      <div
        className={cx(
          'message-detail',
          requires_acknowledgement && 'with-acknowledgement'
        )}
      >
        <article className="message-detail--article">
          <div className="info-header-container">
            <div className="info-header">
              <Avatar
                shape="circle"
                size="medium"
                imgSrc={message.author?.avatar_url}
              />
              <span className="text-black-90 text-body-bold">
                {message.author?.name}
              </span>
              <span className="text-black-60 text-caption">
                {localizeDate(message.published_at)}
              </span>
            </div>
          </div>
          <hr className="bg-black-10" />
          <MessageFrame messageId={message.id} />
        </article>
      </div>
      {requires_acknowledgement && (
        <AcknowledgementFooter messageId={message.id} />
      )}
    </>
  );
};

export default MessageDetail;
