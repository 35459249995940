import MessageDetail from './message-detail/message-detail';
import MessageDetailsNotFound from './message-details-not-found';
import useMessageFetcher from '../../common/use-message-fetcher';
import useMessageViewer from '../../common/use-message-viewer';
import { Spinner } from '../../components/ui';
import { programMembershipSelectors } from '../../models/program-membership';
import './message-details.scss';
import { useContentHeaderMinimizer } from '../../common/use-content-header-minimizer';
import { usePatronSelector } from '../../common/use-patron-selector';
import { useParams } from 'react-router-dom';

const MessageDetailsOrBlank = () => {
  const isActiveMembership = usePatronSelector((state) =>
    programMembershipSelectors.getProgramMembershipIsActive(state)
  );
  return isActiveMembership ? <MessageDetails /> : null;
};

const MessageDetails = () => {
  const params = useParams<{ messageId: string }>();
  useMessageViewer(params.messageId);
  const { message, isFetching } = useMessageFetcher({
    messageId: params.messageId,
  });
  useContentHeaderMinimizer({ loadSuccess: !isFetching && !!message });

  const showNotFound = !isFetching && !message;
  if (showNotFound) return <MessageDetailsNotFound />;

  return (
    <section className="message-details">
      {isFetching ? (
        <Spinner />
      ) : (
        message && <MessageDetail message={message} />
      )}
    </section>
  );
};

export default MessageDetailsOrBlank;
