import {
  BoxContentPreview,
  useResponseInterceptor,
} from '@socialchorus/box-components';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { Spinner } from '../../../components/ui/spinner';
import { trackBoxKmPreviewerOpen } from '../../../models/box-integration/analytics';
import { uiOperations } from '../../../models/ui';
import { BoxUserFolderAccessError, useBoxToken } from '../useBoxToken';

type RouteParams = {
  fileId: string;
};

export function BoxPreview() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fileId } = useParams<RouteParams>();
  const { t } = useTranslation();

  const onError = useCallback(
    (err: unknown) => {
      if (isAxiosError(err)) {
        if (err.response?.status === 403) {
          history.replace('/box/error', {
            errorMessage: t('screens.box.error.forbidden', {
              object: t('common.file'),
            }),
          });
          return;
        }

        if (err.response?.status === 404) {
          history.replace('/box/error', {
            errorMessage: t('screens.box.error.not_found', {
              object: t('common.file'),
            }),
          });
          return;
        }
      }

      if (err instanceof BoxUserFolderAccessError) {
        history.replace('/box/error', {
          errorMessage: t('screens.box.error.forbidden', {
            object: t('common.file'),
          }),
        });
        return;
      }

      throw err;
    },
    [history, t]
  );

  const { token, refetch } = useBoxToken({
    resourceType: 'file',
    resourceId: fileId,
    onError,
  });

  const refetchToken = useCallback(
    async (response: AxiosResponse) => {
      const tokenData = await refetch();

      if (!tokenData) {
        return response;
      }

      return axios.request({
        ...response.config,
        headers: {
          ...response.config.headers,
          Authorization: `Bearer ${tokenData.token}`,
        },
      });
    },
    [refetch]
  );

  const responseInterceptor = useResponseInterceptor({
    refetchToken,
  });

  useEffect(() => {
    dispatch(uiOperations.hideHeader());

    return () => {
      dispatch(uiOperations.showHeader());
    };
  }, [dispatch]);

  useEffect(() => {
    trackBoxKmPreviewerOpen({
      file_id: fileId,
    });
  }, [fileId]);

  return (
    <div className={styles.BoxPreviewContainer}>
      {!token ? (
        <Spinner />
      ) : (
        <BoxContentPreview
          showDownload={false}
          token={token}
          fileId={fileId}
          responseInterceptor={responseInterceptor}
        />
      )}
    </div>
  );
}
