import * as types from './keys.js';
import { track } from '../../analytics/helpers.js';
import store from '../../../config/store.js';
import { programSelectors } from '../../program/index.js';
import ContentService from '../../../services/content.js';
import { contentSelectors } from '../index.js';
import groupBy from 'lodash/groupBy';
import { getTrackingId } from '../../../common/use-tracking-id';

export const contentTraits = (contentId, assignTraits) => {
  const storeState = store.getState();
  const content = contentSelectors.getContentById(storeState, { contentId });
  const emailStarterPackageEnabled =
    programSelectors.getEmailTokenAuthEnabled(storeState);

  const trackingId = getTrackingId();

  return {
    content_id: content && content.id,
    content_type: content && content.content_type,
    feed_item_id: content && content.feed_item_id,
    email_starter: emailStarterPackageEnabled,
    tracking_id: trackingId,
    ...assignTraits,
  };
};

//keeps track of the view events we have already sent, to avoid duplicates while navigating to/from home page and channels.
const cardsViewed = {};
export const trackContentCardView = (contentId, traits) => {
  if (cardsViewed[contentId]) return;

  cardsViewed[contentId] = true;
  track(types.CONTENT_CARD_VIEW, contentTraits(contentId, traits));
};

export const trackContentCardClick = (contentId, traits) => {
  track(types.CONTENT_CARD_CLICK, contentTraits(contentId, traits));
};

export const trackContentCardRead = (contentId, traits) => {
  track(types.CONTENT_CARD_READ, contentTraits(contentId, traits));

  saveContentView(contentId);
};

export const trackContentCardMultiImageClick = (contentId, traits) => {
  track(types.CONTENT_CARD_MULTI_IMAGE_CLICK, contentTraits(contentId, traits));
};

export const trackContentCardLike = (contentId, traits) => {
  track(types.CONTENT_CARD_LIKE, contentTraits(contentId, traits));
};

export const trackContentCardUnlike = (contentId, traits) => {
  track(types.CONTENT_CARD_UNLIKE, contentTraits(contentId, traits));
};

export const trackContentCardFlag = (contentId, traits) => {
  track(types.CONTENT_CARD_FLAG, contentTraits(contentId, traits));
};

export const trackContentCardConfirmFlag = (contentId, traits) => {
  track(types.CONTENT_CARD_FLAG_CONFIRM, contentTraits(contentId, traits));
};
export const trackContentCardConfirmFlagSuccess = (contentId, traits) => {
  track(
    types.CONTENT_CARD_FLAG_CONFIRM_SUCCESS,
    contentTraits(contentId, traits)
  );
};
export const trackContentCardConfirmFlagError = (contentId, traits) => {
  track(
    types.CONTENT_CARD_FLAG_CONFIRM_ERROR,
    contentTraits(contentId, traits)
  );
};

export const trackContentCardBookmark = (contentId, traits) => {
  track(types.CONTENT_CARD_BOOKMARK, contentTraits(contentId, traits));
};

export const trackContentCardUnbookmark = (contentId, traits) => {
  track(types.CONTENT_CARD_UNBOOKMARK, contentTraits(contentId, traits));
};

export const trackContentCardComment = (contentId, traits) => {
  track(types.CONTENT_CARD_COMMENT, contentTraits(contentId, traits));
};

export const trackContentCardShare = (contentId, traits) => {
  track(types.CONTENT_CARD_SHARE, contentTraits(contentId, traits));
};

export const trackContentCardInfo = (contentId, traits) => {
  track(types.CONTENT_CARD_INFO, contentTraits(contentId, traits));
};

export const trackContentCardEdit = (contentId, traits) => {
  track(types.CONTENT_CARD_EDIT, contentTraits(contentId, traits));
};

export const trackContentCardTranslate = (contentId, traits) => {
  track(types.CONTENT_CARD_TRANSLATE, contentTraits(contentId, traits));
};

export const trackContentCardCopyLink = (contentId, traits) => {
  track(types.CONTENT_CARD_COPY_LINK, contentTraits(contentId, traits));
};

export const trackContentCardLikesSeeAll = (contentId, traits) => {
  track(types.CONTENT_CARD_LIKES_SEE_ALL, contentTraits(contentId, traits));
};

export const trackContentCardLikesProfileClick = (traits) => {
  track(types.CONTENT_CARD_LIKES_PROFILE_CLICK, traits);
};

export const trackContentCardAvatarClick = (contentId, traits) => {
  track(types.CONTENT_CARD_AVATAR_CLICK, contentTraits(contentId, traits));
};

export const trackContentCardCommentSortClick = (contentId, traits) => {
  track(
    types.CONTENT_CARD_COMMENT_SORT_CLICK,
    contentTraits(contentId, traits)
  );
};

export const trackContentCardLinkClick = (contentId, traits) => {
  track(types.CONTENT_CARD_LINK_CLICK, contentTraits(contentId, traits));
};

export const trackContentVideoPlay = (contentId, contentType, data, traits) => {
  const eventsByVideoAndInstance = groupBy(
    data,
    (e) => `${e.videoId} - ${e.instanceId}`
  );
  const EVENT_TYPE =
    contentType === 'video'
      ? types.CONTENT_VIDEO_PLAY
      : types.CONTENT_EMBEDDED_VIDEO_PLAY;

  for (const [, events] of Object.entries(eventsByVideoAndInstance)) {
    const segments = [];
    let currentSegment = { start: 0, end: 0 };
    let prevTime = 0;
    let videoId;

    events.forEach((d, i) => {
      const roundTime = Math.round(d.currentTime);

      videoId = d.videoId;

      if (Math.abs(d.currentTime - prevTime) >= 1) {
        // If there's a time jump in the event stream > 1 second, we count it as a new segment
        segments.push(currentSegment);
        currentSegment = { start: roundTime, end: roundTime };
      } else {
        currentSegment.end = roundTime;
      }

      prevTime = d.currentTime;
    });

    segments.push(currentSegment);

    track(
      EVENT_TYPE,
      contentTraits(contentId, {
        ...traits,
        video_id: videoId,
        watched_segments: segments
          .map((segment) => `${segment.start}-${segment.end}`)
          .join(','),
        play_duration: segments.reduce((prev, current) => {
          return prev + (current.end - current.start);
        }, 0),
      })
    );
  }
};

const saveContentView = (contentId) => {
  return new ContentService(contentId).view(); // send view reaction
};
