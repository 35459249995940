import { useLocation } from 'react-router-dom';

//usable outside of a react component
export const getTrackingId = (searchParams?: string): string | null => {
  const params = new URLSearchParams(searchParams || window.location.search);
  const rawId =
    params.get('tracking_id') || params.get('trackingId') || params.get('tok');

  if (!rawId) {
    return null;
  }

  // Accept only letters, numbers, underscore, and dashes. (email tracking ID example is <UUID>_12345)
  const safeRegex = /^[A-Za-z0-9_-]+$/;
  if (!safeRegex.test(rawId)) {
    return null;
  }

  return rawId;
};

/**
 * Normalizes the tracking ID from the URL query parameters.
 * @returns The tracking ID from the URL query parameters.
 */
export const useTrackingId = () => {
  const location = useLocation();
  return getTrackingId(location.search);
};
