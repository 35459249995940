import { uploadAttachments } from '../services/uploads';
import useMediaUploader from './use-media-uploader';

// Wrapper around useMediaUploader.
// UseMediaUploader creates a temporary file in s3 and returns the upload url.
// This wrapper handles the upload to the host image endpoint and returns the final url.
const useHostedMediaUploader = ({
  file,
  onStatusChange,
  onComplete,
  onError,
  programId,
}: {
  file: File | null;
  onStatusChange?: (status: string) => void;
  onComplete: (url: string) => void;
  onError?: (err: Error) => void;
  programId: string;
}) => {
  const onTempFileUploaded = (url: string) => {
    uploadAttachments({ url, programId: programId })
      .then((hostedUrl) => onComplete(hostedUrl))
      .catch((err) => {
        if (onError) onError(err);
      });
  };

  const { status } = useMediaUploader({
    file,
    onStatusChange,
    onComplete: onTempFileUploaded,
    onError,
  });

  return status;
};

export default useHostedMediaUploader;
