import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NotificationContext } from '../context';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { notificationCountsState } from '../../../models/notifications/recoil-state';
import {
  BulkEditAction,
  Notification,
  NotificationCenterTabs,
  FeedPageData,
} from '../../../models/notifications/types';
import {
  bulkEditNotifications,
  BulkEditNotificationsParams,
  BulkEditResponse,
} from '../../../services/notifications';
import { getNotificationFeedQueryKey } from '../feed/use-notification-feed';
import MojoApiFactory from '../../../config/api-factory';
import { AxiosError, AxiosPromise } from 'axios';

export const useBulkEdit = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    tabs: {
      activeTab: [activeTab],
    },
    multiSelect: {
      selectedItems: [multiSelectItems, setMultiSelectedItems],
    },
    filterTags: [filterTags],
    mutationIsLoading: [, setMutationLoading],
  } = useContext(NotificationContext);
  const setNotificationCounts = useSetRecoilState(notificationCountsState);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [undoActionUrl, setUndoActionUrl] = useState<string | undefined>(
    undefined
  );
  const [retryArguments, setRetryArguments] = useState<
    BulkEditNotificationsParams | undefined
  >(undefined);

  const { mutate: bulkEditAction, isLoading: bulkEditIsLoading } = useMutation({
    mutationFn: bulkEditNotifications,
    onMutate: async ({ ids, action }) => {
      //optmimistic update of the data before the mutation is complete.

      const queryKey = getNotificationFeedQueryKey({ activeTab, filterTags });

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Snapshot the previous values
      const previousFeed = queryClient.getQueryData(queryKey);
      const previousMultiSelectedItems = structuredClone(multiSelectItems);

      // Optimistically update the feed data in the cache
      queryClient.setQueryData(
        queryKey,
        (oldData: InfiniteData<FeedPageData> | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          if (
            action === BulkEditAction.ARCHIVE ||
            action === BulkEditAction.UNARCHIVE
          ) {
            return {
              ...oldData,
              pages: oldData.pages.map((page) => ({
                ...page,
                data: page.data.filter((item) => !ids.includes(item.id)),
              })),
            };
          }

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              data: page.data.map((item) => {
                if (ids.includes(item.id)) {
                  return {
                    ...item,
                    ...{
                      read:
                        action === BulkEditAction.MARK_AS_READ
                          ? true
                          : action === BulkEditAction.MARK_AS_UNREAD
                          ? false
                          : item.read,
                    },
                  };
                } else return item;
              }),
            })),
          };
        }
      );

      //Also optmistically update the active multiselected items that are being acted upon
      //(note that it wont nessessarily be all of the items selected)
      setMultiSelectedItems((prev) => {
        if (
          action === BulkEditAction.ARCHIVE ||
          action === BulkEditAction.UNARCHIVE
        ) {
          return prev.filter((item) => !ids.includes(item.id));
        }
        return prev.map((item) => {
          if (ids.includes(item.id)) {
            return {
              ...item,
              ...{
                read:
                  action === BulkEditAction.MARK_AS_READ
                    ? true
                    : action === BulkEditAction.MARK_AS_UNREAD
                    ? false
                    : item.read,
              },
            };
          } else return item;
        });
      });

      // Return a context object with the snapshotted values
      return { previousFeed, previousMultiSelectedItems };
    },
    onSuccess: (data, { action }) => {
      setNotificationCounts(data.data.counts);
      setUndoActionUrl(data.data.undo_action);
      if (action === BulkEditAction.ARCHIVE) {
        setSuccessMessage(
          t('notification_center.archive_success_message', {
            count: data.data.modified.count,
          })
        );
      } else if (action === BulkEditAction.UNARCHIVE) {
        setSuccessMessage(
          t('notification_center.unarchive_success_message', {
            count: data.data.modified.count,
          })
        );
      } else if (action === BulkEditAction.MARK_AS_READ) {
        setSuccessMessage(
          t('notification_center.mark_as_read_success_message', {
            count: data.data.modified.count,
          })
        );
      } else if (action === BulkEditAction.MARK_AS_UNREAD) {
        setSuccessMessage(
          t('notification_center.unmark_as_read_success_message', {
            count: data.data.modified.count,
          })
        );
      }
    },
    onError: (
      err,
      params,
      context:
        | {
            previousFeed: unknown;
            previousMultiSelectedItems: Notification[];
          }
        | undefined
    ) => {
      const { action } = params;
      setRetryArguments(params);

      // Roll back to the previous value
      if (context) {
        const { previousFeed, previousMultiSelectedItems } = context;
        queryClient.setQueryData(
          getNotificationFeedQueryKey({ activeTab, filterTags }),
          previousFeed
        );
        setMultiSelectedItems(previousMultiSelectedItems);
      }

      const response = (err as AxiosError<BulkEditResponse>).response;
      if (response) {
        if (action === BulkEditAction.ARCHIVE) {
          setErrorMessage(
            t('notification_center.archive_error_message', {
              count: response?.data?.unmodified?.count,
            })
          );
        }
        if (action === BulkEditAction.UNARCHIVE) {
          setErrorMessage(
            t('notification_center.unarchive_error_message', {
              count: response?.data?.unmodified?.count,
            })
          );
        }
        if (action === BulkEditAction.MARK_AS_READ) {
          setErrorMessage(
            t('notification_center.mark_as_read_error_message', {
              count: response?.data?.unmodified?.count,
            })
          );
        }
        if (action === BulkEditAction.MARK_AS_UNREAD) {
          setErrorMessage(
            t('notification_center.mark_as_read_error_message', {
              count: response?.data?.unmodified?.count,
            })
          );
        }
      }
    },
    onSettled: (data, err, { action }) => {
      queryClient.invalidateQueries({
        queryKey: ['notifications', activeTab],
      });

      if (action === BulkEditAction.ARCHIVE) {
        queryClient.invalidateQueries({
          queryKey: ['notifications', NotificationCenterTabs.ARCHIVE],
        });
      } else if (action === BulkEditAction.UNARCHIVE) {
        queryClient.invalidateQueries({
          queryKey: ['notifications', NotificationCenterTabs.MESSAGES],
        });
        queryClient.invalidateQueries({
          queryKey: ['notifications', NotificationCenterTabs.ACTIVITY],
        });
      }
    },
  });

  //note that using useMutation here requires there to be only one active success/failure toast at a time, since there will only be one known undo/retry action url stored.
  //the toasts we are using at time of writing work this way when mounted directly, so they naturally work with this approach.
  //but if for some reason we wanted to change the behavior to having multiple toasts at once,
  //this should be refactored to be an action directly on the toast button that calls the api and invalidates the query.
  //that will require queueing the toast in the success/error callbacks of the main mutation.
  //personally, I think only one active toast related to these actions at a time will be a better user experience, so I did it this way.
  //also, useMutation is pretty nice.
  const {
    mutate: undoLastAction,
    isLoading: undoLoading,
    isError: undoIsError,
  } = useMutation({
    mutationFn: async () => {
      if (!undoActionUrl) {
        console.error('no undo action url'); //shouldnt be reachable code.
        return;
      }
      return MojoApiFactory.base.put(undoActionUrl) as AxiosPromise;
    },
    //no optimistic update here, as its fairly complicated to implement and this isnt going to be a common use case.
    //instead, going to communicate a loading status.
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'], //invalidate everything, since this will be accessible from a toast, and so we have no idea of the scope of the action.
      });
    },
  });

  const {
    mutate: retryLastAction,
    isLoading: retryLoading,
    isError: retryIsError,
  } = useMutation({
    mutationFn: async () => {
      if (retryArguments === undefined) {
        console.error('no retry action parameters'); //shouldnt be reachable code.
        return;
      }
      await bulkEditNotifications(retryArguments);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
    },
  });

  const archivableItems = useMemo(
    () =>
      multiSelectItems.filter(
        (item) => !item.archived && !item.action_required && !item.important
      ),
    [multiSelectItems]
  );
  const unArchivableItems = useMemo(
    () => multiSelectItems.filter((item) => item.archived),
    [multiSelectItems]
  );
  const markableAsReadItems = useMemo(
    () => multiSelectItems.filter((item) => !item.read),
    [multiSelectItems]
  );
  const markableAsUnreadItems = useMemo(
    () => multiSelectItems.filter((item) => item.read),
    [multiSelectItems]
  );

  //todo: modal confirmation if some of the selected items are not archived and also important or action required
  const someSelectedItemsNotArchivable = useMemo(
    () =>
      multiSelectItems.some(
        (item) => !item.archived && (item.important || item.action_required)
      ),
    [multiSelectItems]
  );

  const allSelectedItemsNotArchivable = useMemo(
    () =>
      multiSelectItems.length > 0 &&
      multiSelectItems
        .filter((item) => !item.archived)
        .every((item) => item.important || item.action_required),
    [multiSelectItems]
  );

  const archiveItems = useCallback(() => {
    bulkEditAction({
      ids: archivableItems.map((n) => n.id),
      action: BulkEditAction.ARCHIVE,
    });
  }, [archivableItems, bulkEditAction]);

  const unarchiveItems = useCallback(() => {
    bulkEditAction({
      ids: unArchivableItems.map((n) => n.id),
      action: BulkEditAction.UNARCHIVE,
    });
  }, [unArchivableItems, bulkEditAction]);

  const markItemsAsRead = useCallback(() => {
    bulkEditAction({
      ids: markableAsReadItems.map((n) => n.id),
      action: BulkEditAction.MARK_AS_READ,
    });
  }, [markableAsReadItems, bulkEditAction]);

  const markItemsAsUnread = useCallback(() => {
    return bulkEditAction({
      ids: markableAsUnreadItems.map((n) => n.id),
      action: BulkEditAction.MARK_AS_UNREAD,
    });
  }, [markableAsUnreadItems, bulkEditAction]);

  useEffect(() => {
    if (bulkEditIsLoading) {
      setSuccessMessage(undefined);
      setErrorMessage(undefined);
    }
  }, [bulkEditIsLoading]);

  useEffect(() => {
    if (undoLoading || retryLoading) {
      setMutationLoading(true);
    } else setMutationLoading(false);
  }, [undoLoading, retryLoading, setMutationLoading]);

  return {
    items: {
      archivableItems,
      unArchivableItems,
      markableAsReadItems,
      markableAsUnreadItems,
      someSelectedItemsNotArchivable,
      allSelectedItemsNotArchivable,
    },
    actions: {
      archiveItems,
      unarchiveItems,
      markItemsAsRead,
      markItemsAsUnread,
    },
    success: {
      successMessage,
      //important to wrap this method, as we dont want to pass any variables to it accidentally.
      undoLastAction:
        (undoActionUrl !== undefined && (() => undoLastAction())) || undefined,
      undoIsError,
    },
    error: {
      errorMessage,
      //important to wrap this method, as we dont want to pass any variables to it accidentally.
      retryLastAction:
        (retryArguments !== undefined && (() => retryLastAction())) ||
        undefined,
      retryIsError,
    },
  };
};
