import React, { useContext } from 'react';
import cx from 'classnames';
import { useNotificationFeed } from './use-notification-feed';
import { NotificationCard, Toast } from '@socialchorus/shared-ui-components';
import { Spinner } from '../../../components/ui';
import ViewTrigger from '../../../components/view-trigger';
import styles from '../notification-center.module.scss';
import { NotificationContext } from '../context';
import { useTranslation } from 'react-i18next';
import { NotificationCardWrapper } from './notification-card';

export const NotificationFeed: React.FC = () => {
  const { t } = useTranslation();
  const {
    mutationIsLoading: [mutationLoading],
  } = useContext(NotificationContext);
  const {
    pages,
    isLoading,
    canLoadMore,
    isLoadingMore,
    fetchNextPage,
    isError,
  } = useNotificationFeed();

  const feed = pages.map((page) => page.data).flat();
  const feedEmpty = feed.length === 0;

  return (
    <div>
      {isLoading || mutationLoading ? (
        <Spinner />
      ) : feedEmpty ? (
        <>
          <div className={styles.emptyFeed}>
            <div
              className={cx(styles.emptyFeedLabel, 'text-body-1 text-black-90')}
            >
              {t('notification_center.empty_feed')}
            </div>
          </div>
          <hr className="bg-black-10" />
        </>
      ) : (
        <>
          <NotificationCard.FeedContainer>
            {feed.map((notification) => (
              <NotificationCardWrapper
                key={notification.id}
                notification={notification}
              />
            ))}
          </NotificationCard.FeedContainer>
          {isLoadingMore ? (
            <Spinner />
          ) : (
            canLoadMore && (
              <ViewTrigger offset="200px" onInview={() => fetchNextPage()} />
            )
          )}
        </>
      )}
      {isError && (
        <Toast
          title={t('notification_center.feed_error_message')}
          variant="error"
          defaultOpen
        />
      )}
    </div>
  );
};
