import { useContext, useEffect } from 'react';
import { MobileOverlayKeys, NotificationContext } from '../context';
import {
  ArchivePartialConfirmationMobileOverlay,
  ArchivePartialConfirmationMobileOverlayProps,
} from './archive-partial-confirmation';
import { FilterMobileOverlay, FilterMobileOverlayProps } from './filter';
import {
  ToolbarExtraOptionsMobileOverlay,
  ToolbarExtraOptionsMobileOverlayProps,
} from './toolbar-extra-options';
import useScreenSize from '../../../common/use-screen-size';
import styles from './../notification-center.module.scss';
import ReactFocusLock from 'react-focus-lock';

const OverlayComponent = () => {
  const {
    mobileOverlay: { activeId, props },
  } = useContext(NotificationContext);

  if (activeId === MobileOverlayKeys.ARCHIVE_PARTIAL_CONFIRMATION) {
    return (
      <ArchivePartialConfirmationMobileOverlay
        {...(props as ArchivePartialConfirmationMobileOverlayProps)}
      />
    );
  } else if (activeId === MobileOverlayKeys.FILTER) {
    return <FilterMobileOverlay {...(props as FilterMobileOverlayProps)} />;
  } else if (activeId === MobileOverlayKeys.TOOLBAR_EXTRA_OPTIONS) {
    return (
      <ToolbarExtraOptionsMobileOverlay
        {...(props as ToolbarExtraOptionsMobileOverlayProps)}
      />
    );
  } else return null;
};

export const MobileOverlayController = () => {
  const {
    mobileOverlay: { activeId, close },
  } = useContext(NotificationContext);

  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (!isMobile && !!activeId) {
      close();
    }
  }, [isMobile, activeId, close]);

  const showMobileScreen = isMobile && !!activeId;

  if (!showMobileScreen) return null;

  return (
    <ReactFocusLock disabled={!activeId} group="mobile-overlay">
      <div className={styles.overlayContainer} aria-modal>
        <OverlayComponent />
      </div>
    </ReactFocusLock>
  );
};
