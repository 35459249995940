import { useSetRecoilState } from 'recoil';
import { notificationCountsState } from '../../models/notifications/recoil-state';
import { useEffect } from 'react';
import { getNotificationCounts } from '../../services/notifications';
import { useQuery } from '@tanstack/react-query';

export const useNotificationCountsLoader = () => {
  const setNotificationCounts = useSetRecoilState(notificationCountsState);

  const { data } = useQuery({
    queryKey: ['notifications/counts'],
    queryFn: getNotificationCounts,
  });

  useEffect(() => {
    if (data) {
      setNotificationCounts(data.data);
    }
  }, [data, setNotificationCounts]);

  return null;
};
