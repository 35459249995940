import { useProgram } from '../../../../common/use-program';
import { SearchParams, useSearchQueryParams, stringify } from '../query-params';
import { Program } from '../../../../models/program/types';
import { ResourceObject } from '../../../../lib/types/json-api';
import { Channel } from '../../../../models/channels/types';
import { TopicCard } from './topic-card';
import { TopicCardV2 } from './topic-card-v2';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../../skip-to-content/skip-to-content';
import useFeatureFlag from '../../../../common/use-feature-flag';
import { Feature } from '../../../../models/features/features';
import styles from './search-topics.module.scss';
import { InfiniteSearchResults, useInfiniteSearch } from '../infinite-search';
import { TopicsLoadingSkeleton } from './topics-loading-skeletion';
import { VisuallyHidden } from '../../visually-hidden';
import { useLocalizedScreenReaderLoadingMessage } from '../locale';
import { NoResultsFound } from '../no-results-found';
import { createCacheKey, optimus, parseCacheKey } from '../http';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CompleteError } from '../overview/overview';
import { trackChannelClick } from '../../../../models/channels/analytics';
import { trackSearchChannelCardView } from '../../../../models/analytics';
import { SearchResultState } from '../search-screen';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';

type Topic = Channel & {
  [key: string]: string;
};

export type TopicSearchResponse = {
  data: ResourceObject<'content_channel', Topic>[];
};

export async function searchTopics(
  programId: Program['id'],
  params: SearchParams
) {
  const query = stringify(params);

  return optimus
    .get<TopicSearchResponse>(
      `/v1/programs/${programId}/search/topics?${query}`
    )
    .then((response) => response.data);
}

export const topicsCacheKey = createCacheKey('topics');
export function useTopicSearch(searchParams: SearchParams) {
  const programId = useProgram().id;
  const {
    data: topicSearchResponse,
    isLoading,
    ...rest
  } = useInfiniteSearch(
    (page) => [topicsCacheKey({ ...searchParams, page: page + 1 })],
    ([cacheKey]) => searchTopics(programId, parseCacheKey(cacheKey).params)
  );

  const isLoadingMore = Boolean(
    isLoading ||
      (topicSearchResponse &&
        topicSearchResponse[topicSearchResponse.length - 1] === undefined)
  );

  const isEmpty = topicSearchResponse?.[0]?.data.length === 0;
  const isReachingEnd = Boolean(
    isEmpty ||
      (topicSearchResponse &&
        topicSearchResponse[topicSearchResponse.length - 1]?.data.length <
          Number(searchParams?.perPage))
  );

  return {
    topicsData: topicSearchResponse,
    isLoadingMore,
    isReachingEnd,
    ...rest,
  };
}

interface TopicsProps {
  searchReturnedResults?: (searchResultState: SearchResultState) => void;
}
export function useMemberCount(initalMemberCount?: number) {
  const [memberCount, setMemberCount] = useState<number>(
    initalMemberCount ?? 0
  );

  const handleFollowUnfollow = (following: boolean) => {
    if (following) {
      setMemberCount((count) => count - 1);
    } else {
      setMemberCount((count) => count + 1);
    }
  };

  return { memberCount, handleFollowUnfollow };
}

export function TopicSearch({ searchReturnedResults }: TopicsProps) {
  const isAssistantActive = useIsAssistantActive();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });
  const { t } = useTranslation();
  const {
    topicsData,
    setSize,
    size,
    isLoadingMore,
    isValidating,
    isReachingEnd,
    error,
    mutate: retry,
  } = useTopicSearch(query);

  const topicLandingPage = useFeatureFlag(
    Feature.TOPIC_LANDING_PAGES_V2_ENABLED
  );
  const EnabledTopicCard = topicLandingPage ? TopicCardV2 : TopicCard;
  const srOnlyLoadingMessage = useLocalizedScreenReaderLoadingMessage('topics');

  const retryTopicSearch = () => {
    retry();
  };

  useEffect(() => {
    if (topicsData !== undefined && topicsData[0].data.length > 0) {
      trackSearchChannelCardView();
    }
  }, [topicsData]);

  const analyticsData = {
    location: 'search_topics',
    searchType: 'topics',
    searchTerm: query.query,
  };
  if (searchReturnedResults && !topicsData) {
    searchReturnedResults(SearchResultState.Loading);
  }
  if (searchReturnedResults && topicsData !== undefined && !error) {
    searchReturnedResults(SearchResultState.HasResults);
  }

  if (topicsData !== undefined) {
    return (
      <section id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {topicsData[0]?.data.length > 0 ? (
          <InfiniteSearchResults
            isLoadingMore={isValidating || isLoadingMore}
            isReachingEnd={isReachingEnd}
            onViewTrigger={() => setSize(size + 1)}
            errorFetching={Boolean(error)}
            handleRetry={retryTopicSearch}
          >
            <ul className={styles.TopicsContainer}>
              {topicsData.map((topics) =>
                topics.data.map((topic) => {
                  return (
                    <li
                      key={topic.id}
                      onClick={() => trackChannelClick(topic.id, analyticsData)}
                    >
                      <EnabledTopicCard
                        topic={topic.attributes}
                        analyticsData={analyticsData}
                      />
                    </li>
                  );
                })
              )}
            </ul>
          </InfiniteSearchResults>
        ) : (
          <NoResultsFound
            title={t('search.no_results_type.title', {
              type: t(`search.types.topics`),
            })}
            description={t('search.no_results_type.description')}
          />
        )}
      </section>
    );
  }

  if (error) {
    return (
      <CompleteError
        query={query.query ? query.query : ''}
        handleRetry={retryTopicSearch}
      />
    );
  }

  return (
    <>
      <VisuallyHidden id={DEFAULT_SKIP_TO_CONTENT_ID}>
        {srOnlyLoadingMessage}
      </VisuallyHidden>
      <div className={styles.TopicsContainer} aria-busy aria-live="polite">
        {Array.from({ length: 8 }).map((_, index) => (
          <TopicsLoadingSkeleton key={index} />
        ))}
      </div>
    </>
  );
}
