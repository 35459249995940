import { Button, Icon } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import styles from './../notification-center.module.scss';
import { useContext } from 'react';
import { NotificationContext } from '../context';

export type ToolbarExtraOptionsMobileOverlayProps = {
  onArchiveClick: () => void;
  onEnableMultiSelectClick: () => void;
};

export const ToolbarExtraOptionsMobileOverlay = ({
  onArchiveClick,
  onEnableMultiSelectClick,
}: ToolbarExtraOptionsMobileOverlayProps) => {
  const { t } = useTranslation();

  const {
    mobileOverlay: { close },
  } = useContext(NotificationContext);

  const handleArchiveClick = () => {
    onArchiveClick();
    close();
  };

  const handleEnableMultiSelectClick = () => {
    onEnableMultiSelectClick();
    close();
  };

  return (
    <div className={styles.toolbarMobileScreenContainer}>
      <div className={styles.buttonMenu}>
        <Button
          variant="text"
          label={t('common.back')}
          leftIcon={<Icon>arrow_back</Icon>}
          onClick={() => close()}
        />
      </div>
      <div className={styles.optionContainer}>
        <Button
          className={styles.optionButton}
          variant="text"
          label={t('notification_center.view_archive')}
          leftIcon={<Icon>inventory_2</Icon>}
          fullWidth
          onClick={handleArchiveClick}
        />
        <Button
          className={styles.optionButton}
          variant="text"
          label={t('notification_center.enable_multi_select')}
          leftIcon={<Icon>edit_note</Icon>}
          fullWidth
          onClick={handleEnableMultiSelectClick}
        />
      </div>
    </div>
  );
};
