import { AssistantBackBar, AssistantAction } from '../../components/assistant';
import { trackAssistantIntegrationLinkClick } from '../../models/assistant/analytics';
import { CommandButton } from '../../models/assistant/interfaces/Command';
import { useCommandsQuery } from '../../components/assistant/queries';

export const AssistantCommandsList = () => {
  const { data: commands } = useCommandsQuery();
  const handleIntegrationLinkClick = (
    { tracking_context }: CommandButton,
    order: number
  ) => {
    trackAssistantIntegrationLinkClick({
      service_id: tracking_context.service_id,
      service_name: tracking_context.service_name,
      link_name: tracking_context.command_name,
      order,
    });
  };

  return (
    <div className="assistant__commands assistant__search-view">
      <div className="assistant__nav-bar">
        <AssistantBackBar />
      </div>
      <ul>
        {commands &&
          commands.map(
            (command, index) =>
              command.action && (
                <li className="assistant__command" key={`command-${index}`}>
                  <AssistantAction
                    onClick={() => handleIntegrationLinkClick(command, index)}
                    action={command.action}
                    actionTitle={command.button_text}
                    asCommand
                  >
                    <img
                      alt=""
                      width="28"
                      height="28"
                      className="button-image"
                      src={command.button_image_url}
                    />
                    <span className="text">{command.button_text}</span>
                  </AssistantAction>
                </li>
              )
          )}
      </ul>
    </div>
  );
};

export default AssistantCommandsList;
