import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from '../../../ui';
import './secondary-search-nav.scss';
import cx from 'classnames';
import { SearchType, useSearchRoute } from '../search-screen';
import { useLocation } from 'react-router';
import { optimus } from '../http';
import { useProgram } from '../../../../common/use-program';
import { trackSearchCategoryClick } from '../../../../models/analytics';
import { LoadingSkeleton } from '../../loading-skeleton';
import { useIsAssistantActive } from '../../../../common/useIsAssistantActive';
import { useSearchQueryParams } from '../query-params';
import useSWR from 'swr';

export interface NavItem {
  url: string;
  title: string;
  type: SearchType;
}

interface SecondarySearchNavProps {
  navItems: NavItem[];
}

export type SearchResultCounts = {
  counts: {
    overview: number;
    posts: number;
    people: number;
    topics: number;
    documents: number;
    shortcuts: number;
  };
};

export const useResultCountFetcher = (query: string | null | undefined) => {
  const program = useProgram();
  const { data, ...rest } = useSWR(
    ['search_result_count', query],
    async () => {
      const encodedQuery = encodeURIComponent(query || '');
      const searchResultCounts = await optimus.get<SearchResultCounts>(
        `v1/programs/${program.id}/search/meta/counts?query=${encodedQuery}`
      );

      return searchResultCounts;
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    searchResultCounts:
      data?.data ||
      ({
        counts: { overview: 0, posts: 0, people: 0, topics: 0, shortcuts: 0 },
      } as SearchResultCounts),
    ...rest,
  };
};

const SecondarySearchNav: React.FC<SecondarySearchNavProps> = ({
  navItems,
}) => {
  const { searchType, integrationId } = useSearchRoute();
  const { search } = useLocation();
  const { t } = useTranslation();
  const isAssistantActive = useIsAssistantActive();
  const [query] = useSearchQueryParams({
    disabled: isAssistantActive,
  });
  const regex = new RegExp(`/enterprise/${integrationId}/`);

  const { searchResultCounts, isLoading } = useResultCountFetcher(query.query);

  const getCountForType = (type: SearchType): number | undefined => {
    //Skip enterprise search type
    if (type === 'enterprise' || !searchResultCounts) {
      return undefined;
    }
    return searchResultCounts.counts[type] ?? 0;
  };

  if (isLoading) {
    return <SecondarySearchNavLoadingSkeleton />;
  }

  return (
    <>
      <nav
        className="secondary-search-nav"
        aria-describedby="search-heading-label"
      >
        <div className="secondary-search-nav__container">
          <h2 className="secondary-search-nav-title" id="search-heading-label">
            <p>
              {t('search.results_count_for', {
                count: searchResultCounts?.counts['overview'] ?? 0,
              })}{' '}
              &quot;
            </p>
            <strong>{query.query}</strong>&quot;
          </h2>
          <ul className="secondary-search-nav-pills">
            {navItems.map((item) => (
              <li key={item.url}>
                <NavLink
                  href={item.url + search}
                  exact
                  className={cx('secondary-search-nav__link', {
                    'is-active':
                      searchType === 'enterprise'
                        ? searchType === item.type && regex.test(item.url)
                        : searchType === item.type,
                  })}
                  onClick={() => {
                    const itemType =
                      item.type === 'enterprise' ? 'integrated' : item.type;
                    trackSearchCategoryClick({ type: itemType });
                  }}
                >
                  {item.title}{' '}
                  {getCountForType(item.type) !== undefined && (
                    <span className="pill-interior-result-count">
                      {getCountForType(item.type)}
                    </span>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};

export function SecondarySearchNavLoadingSkeleton() {
  return (
    <nav
      className="secondary-search-nav"
      aria-describedby="search-heading-label"
    >
      <div className="secondary-search-nav__container">
        <LoadingSkeleton width={'30%'} height="14px" />
        <LoadingSkeleton width={'22%'} height="14px" />
        <div className={'secondary-search-nav-pills'}>
          <LoadingSkeleton width={'8%'} height={'24px'} marginTop={'15px'} />
          <LoadingSkeleton width={'8%'} height={'24px'} marginTop={'15px'} />
          <LoadingSkeleton width={'8%'} height={'24px'} marginTop={'15px'} />
          <LoadingSkeleton width={'8%'} height={'24px'} marginTop={'15px'} />
          <LoadingSkeleton width={'8%'} height={'24px'} marginTop={'15px'} />
          <LoadingSkeleton width={'8%'} height={'24px'} marginTop={'15px'} />
        </div>
      </div>
    </nav>
  );
}

export default SecondarySearchNav;
