import { Comment, CommentWithReplies, isCommentWithReplies } from '../types';
import { toDate } from '../../../lib/date-formatter';

type State = (Comment | CommentWithReplies)[];

export enum SortOrder {
  TOP = 'top',
  NEWEST = 'newest',
  OLDEST = 'oldest',
  MOST_LIKED = 'most_liked',
  MOST_REPLIED = 'most_replied',
}

type TreeOperation<T> = (
  comment: Comment | CommentWithReplies,
  acc: State
) => [boolean, State, T | undefined];

function traverseCommentTree<T>(
  comments: State,
  operation: TreeOperation<T>
): [boolean, State, T | undefined] {
  return comments.reduce<[boolean, State, T | undefined]>(
    ([found, acc, result], comment) => {
      if (found) {
        return [true, [...acc, comment], result];
      }

      // Try the operation on the current comment
      const [operationFound, operationAcc, operationResult] = operation(
        comment,
        acc
      );

      if (operationFound) {
        return [true, operationAcc, operationResult];
      }

      // If this comment has replies, search through them
      if (isCommentWithReplies(comment) && comment.replies.length > 0) {
        const [foundInReplies, updatedReplies, repliesResult] =
          traverseCommentTree(comment.replies, operation);

        if (foundInReplies) {
          return [
            true,
            [
              ...acc,
              {
                ...comment,
                replies: updatedReplies,
              },
            ],
            repliesResult,
          ];
        }
      }

      return [false, [...acc, comment], undefined];
    },
    [false, [], undefined]
  );
}

function addCommentToList(
  state: State,
  comment: Comment,
  sortOrder: SortOrder
): State {
  if (
    [SortOrder.OLDEST, SortOrder.MOST_LIKED, SortOrder.MOST_REPLIED].includes(
      sortOrder
    )
  ) {
    //put new comment last (is assuming no negative like/reply count)
    return [...state, comment];
  } else if (sortOrder === SortOrder.TOP) {
    //put new comment after the last highlighted comment
    return [comment, ...state].sort((leftComment, rightComment) => {
      if (leftComment.highlightedAt && !rightComment.highlightedAt) {
        return -1;
      }
      if (!leftComment.highlightedAt && rightComment.highlightedAt) {
        return 1;
      }
      if (toDate(leftComment.createdAt) > toDate(rightComment.createdAt)) {
        return -1;
      }
      if (toDate(leftComment.createdAt) < toDate(rightComment.createdAt)) {
        return 1;
      }
      return 0;
    });
  } else {
    //put new comment first
    return [comment, ...state];
  }
}

export function addComment(
  state: State,
  {
    comment,
    sortOrder,
  }: {
    comment: Comment;
    sortOrder: SortOrder;
  }
): State {
  // If this is a reply to another comment
  if (comment.replyToId) {
    const addReplyOperation: TreeOperation<void> = (currentComment, acc) => {
      if (currentComment.id === comment.replyToId) {
        // Convert the comment to CommentWithReplies if it isn't already
        const commentWithReplies: CommentWithReplies = isCommentWithReplies(
          currentComment
        )
          ? currentComment
          : {
              ...currentComment,
              replies: [],
              repliesPageCount: 1,
              repliesCurrentPage: 1,
            };

        // Add the reply based on sort order
        const updatedReplies = addCommentToList(
          commentWithReplies.replies,
          comment,
          sortOrder
        );

        return [
          true,
          [
            ...acc,
            {
              ...commentWithReplies,
              replies: updatedReplies,
              replyCount: (commentWithReplies.replyCount || 0) + 1,
            },
          ],
          undefined,
        ];
      }

      return [false, acc, undefined];
    };

    const [found, newState] = traverseCommentTree(state, addReplyOperation);

    // If parent comment wasn't found, add it to the top level
    // This can happen if the parent comment was deleted
    if (!found) {
      return addCommentToList(state, comment, sortOrder);
    }

    return newState;
  }

  // If this is a top-level comment
  return addCommentToList(state, comment, sortOrder);
}

export function updateComment(
  state: State,
  { comment }: { comment: Partial<Comment> }
): State {
  const updateOperation: TreeOperation<void> = (currentComment, acc) => {
    if (currentComment.id === comment.id) {
      return [
        true,
        [
          ...acc,
          {
            ...currentComment,
            ...comment,
            // Preserve id and replyToId from the original comment
            id: currentComment.id,
            replyToId: currentComment.replyToId,
          },
        ],
        undefined,
      ];
    }

    return [false, acc, undefined];
  };

  const [found, newState] = traverseCommentTree(state, updateOperation);

  // If the comment wasn't found, return the original state
  if (!found) {
    return state;
  }

  return newState;
}

export function removeComment(
  state: State,
  { comment }: { comment: Comment }
): State {
  const removeOperation: TreeOperation<number> = (currentComment, acc) => {
    if (currentComment.id === comment.id) {
      // Return true (found), accumulator without this comment, and the number of replies + 1
      // This count will be used to update parent's replyCount
      const totalReplies = isCommentWithReplies(currentComment)
        ? currentComment.replyCount || 0
        : 0;
      return [true, acc, totalReplies + 1];
    }

    return [false, acc, undefined];
  };

  // If this is a reply, we need to update the parent's replyCount
  if (comment.replyToId) {
    const updateParentOperation: TreeOperation<void> = (
      currentComment,
      acc
    ) => {
      if (currentComment.id === comment.replyToId) {
        // First remove the comment from replies
        const [, updatedReplies, removedCount] = traverseCommentTree(
          (currentComment as CommentWithReplies).replies,
          removeOperation
        );

        if (!removedCount) {
          return [false, acc, undefined];
        }

        return [
          true,
          [
            ...acc,
            {
              ...currentComment,
              replies: updatedReplies,
              replyCount: Math.max(
                0,
                (currentComment.replyCount || 0) - removedCount
              ),
            },
          ],
          undefined,
        ];
      }

      return [false, acc, undefined];
    };

    const [found, newState] = traverseCommentTree(state, updateParentOperation);

    // If parent wasn't found (might have been deleted), just remove the comment from wherever it is
    if (!found) {
      const [, filteredState] = traverseCommentTree(state, removeOperation);
      return filteredState;
    }

    return newState;
  }

  // For top-level comments, just remove the comment and its replies
  const [, newState] = traverseCommentTree(state, removeOperation);
  return newState;
}
