import { usePatronSelector } from '../../common/use-patron-selector';
import { Message } from '../../models/messages/types';
import { programSelectors } from '../../models/program';
import { useRecoilState } from 'recoil';
import { messageCompletedState } from '../../models/messages/recoil-state';
import { useMemo, useRef } from 'react';
import { Monitor } from '../content/content-completion/types';
import contentCompletion from '../content/content-completion';
import useIframeResize from '../../common/useIframeResize';
import { useTrackingId } from '../../common/use-tracking-id';

type MessageFrameProps = {
  messageId: Message['id'];
};

const MessageFrame = ({ messageId }: MessageFrameProps) => {
  const trackingId = useTrackingId();
  const programId = usePatronSelector((state) =>
    programSelectors.getProgramId(state)
  );
  const iframeMessageSrc = `/embed/messages/${messageId}?program=${programId}${
    trackingId ? `&trackingId=${trackingId}` : ''
  }`;
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const handleIframeRefChange = (node: HTMLIFrameElement | null) => {
    iframeRef.current = node;
  };

  const { height, ref: resizedIframeRef } = useIframeResize({
    onRefChange: handleIframeRefChange,
  });

  const [isContentCompleted, setIsContentCompleted] = useRecoilState(
    messageCompletedState(messageId)
  );

  const completionMonitor = useMemo(() => {
    if (isContentCompleted) return undefined;

    const handleContentCompleted = (monitor: Monitor) => {
      if (iframeRef.current) {
        monitor.detach();
      }
      setIsContentCompleted(true);
    };

    const monitor = contentCompletion['article'];
    return monitor ? new monitor(handleContentCompleted) : undefined;
  }, [messageId, isContentCompleted, setIsContentCompleted]);

  const iframe = iframeRef.current;

  if (completionMonitor && iframe) {
    completionMonitor.attach(iframe);
  }

  return (
    <iframe
      allowFullScreen
      src={iframeMessageSrc}
      title={messageId}
      ref={resizedIframeRef}
      scrolling="no"
      style={{ height: `${height}px` }}
      height={height}
    />
  );
};

export default MessageFrame;
