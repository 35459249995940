import { useQuery } from '@tanstack/react-query';
import AssistantService from '../../services/assistant';

export const useCommandsQuery = () =>
  useQuery({
    queryKey: ['assistant/commands'],
    queryFn: new AssistantService().fetchCommands,
    staleTime: 10 * 60 * 1000, // 10 minutes
  });

export const useIntegrationsQuery = () =>
  useQuery({
    queryKey: ['assistant/integrations'],
    queryFn: new AssistantService().fetchIntegrations,
    staleTime: 10 * 60 * 1000, // 10 minutes
  });
