import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Button } from '@socialchorus/shared-ui-components';
import styles from '../notification-center.module.scss';
import { Modal } from '../../../components/ui';

export const ArchivePartialConfirmationModalID =
  'Overlays.NOTIFICATION_CENTER_ARCHIVE_PARTIAL_CONFIRMATION';

export type ArchivePartialConfirmationProps = {
  archivableCount: number;
  totalCount: number;
  onConfirm: () => void;
  close: () => void;
};

export const ArchivePartialConfirmationModal = ({
  archivableCount,
  totalCount,
  onConfirm,
  close,
}: ArchivePartialConfirmationProps) => {
  const { t } = useTranslation();

  const handleContinue = useCallback(() => {
    onConfirm();
    close();
  }, [onConfirm, close]);

  return (
    <Modal className={styles.archiveConfirmModal} lockScroll onClose={close}>
      <header className={cx(styles.header, 'text-heading text-black-90')}>
        {t('notification_center.archive_confirm_modal.title')}
      </header>
      <div className={styles.body}>
        <div className={cx(styles.description, 'text-body-bold text-black-90')}>
          {t('notification_center.archive_confirm_modal.description', {
            count: archivableCount,
            total: totalCount,
          })}
        </div>
        <div className={cx(styles.explaination, 'text-body text-black-90')}>
          {t('notification_center.archive_confirm_modal.explaination')}
        </div>
      </div>
      <footer className={styles.footer}>
        <Button
          variant="outline"
          size="compact"
          label={t('common.cancel')}
          onClick={close}
        />
        <Button
          size="compact"
          variant="primary"
          label={t('common.continue')}
          onClick={handleContinue}
        />
      </footer>
    </Modal>
  );
};
