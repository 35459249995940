import { CommentAttachment as CommentAttachmentType } from '../../../models/comments/types';

const CommentAttachment = ({
  attachment,
  onClick,
}: {
  attachment: CommentAttachmentType;
  onClick: () => void;
}) => {
  return (
    <button onClick={onClick} className="comment__content--attachments--image">
      <img src={attachment.url} alt={attachment.altText} />
    </button>
  );
};

export default CommentAttachment;
