import { atom } from 'recoil';
import { NotificationCounts } from './types';

export const notificationCountsState = atom<NotificationCounts>({
  key: 'notificationCountsState',
  default: {
    total_alert: 0,
    messages: {
      unread: 0,
      important: 0,
      action_required: 0,
    },
    activities: {
      unread: 0,
    },
    archived: {
      unread: 0,
    },
  },
});
