import { LocationDescriptorObject } from 'history';
import { RouteComponentProps } from 'react-router';
import { AssistantSidePanel } from './assistant-side-panel';
import { Assistant as LegacyAssistant } from './assistant';
import { uiSelectors } from '../../models/ui';
import useFeatureFlag from '../../common/use-feature-flag';
import { Feature } from '../../models/features/features';
import { connect, MapStateToProps } from 'react-redux';
import { RootPatronState } from '../../common/use-patron-selector';
import { AssistantRoot } from './assistant-root';

type StateProps = {
  overlayIsActive: ReturnType<typeof uiSelectors.getOverlayIsActive>;
};

type AssistantProps = RouteComponentProps &
  StateProps & {
    goBack: LocationDescriptorObject | null;
  };

const Assistant: React.FC<AssistantProps> = (props) => {
  const notificationCenterEnabled = useFeatureFlag(
    Feature.NOTIFICATION_CENTER_ENABLED
  );

  return notificationCenterEnabled ? (
    <AssistantSidePanel {...props}>
      <AssistantRoot {...props} />
    </AssistantSidePanel>
  ) : (
    <LegacyAssistant {...props} />
  );
};

const mapStateToProps: MapStateToProps<StateProps, never, RootPatronState> = (
  state
) => ({
  overlayIsActive: uiSelectors.getOverlayIsActive(state),
});

export default connect(mapStateToProps, null)(Assistant);
