import { ComponentProps, JSXElementConstructor, useMemo } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import { LocationDescriptorObject } from 'history';

import AssistantNav from './assistant-nav';
import AssistantError from './assistant-error';

import Landing from './assistant-landing';
import Explore from './assistant-explore';
import Notifications from './assistant-notifications';
import Commands from './assistant-commands';
import SummaryDetail from './assistant-summary-detail';
import Integration from './assistant-integration';
import Todos from './assistant-todos';
import Contents from './assistant-contents';
import Profiles from './assistant-profiles';
import Channels from './assistant-channels';
import Resources from './assistant-resources';
import CommandRunner from './assistant-command-runner';
import AnsweredPolls from './assistant-answered-polls';

import { Curtain } from '../../components/ui';
import ErrorBoundary from '../../components/error-boundary';

import useAssistantFetcher, {
  AssistantDataType,
} from '../../common/use-assistant-fetcher';

import './assistant.scss';
import { uiSelectors } from '../../models/ui';
import NestedMemoryRouter from '../../components/nested-router/nested-memory-router';
import useFeatureFlag from '../../common/use-feature-flag';
import { Feature } from '../../models/features/features';

type StateProps = {
  overlayIsActive: ReturnType<typeof uiSelectors.getOverlayIsActive>;
};

type AssistantProps = RouteComponentProps &
  StateProps & {
    goBack: LocationDescriptorObject | null;
  };

export function Assistant({
  history,
  match,
  goBack,
  overlayIsActive,
}: AssistantProps) {
  const { t } = useTranslation();

  const notificationCenterEnabled = useFeatureFlag(
    Feature.NOTIFICATION_CENTER_ENABLED
  );

  const handleCloseClick = () => {
    history.goBack();
  };

  const onRouteExit: ComponentProps<
    typeof NestedMemoryRouter
  >['onRouteExit'] = (nextLocation) => {
    if (nextLocation.pathname === goBack?.pathname) {
      handleCloseClick();
    } else {
      history.replace(nextLocation);
    }
  };

  // Data needed by every assistant screen
  const { isError } = useAssistantFetcher({
    itemTypes: [
      AssistantDataType.INTEGRATIONS,
      AssistantDataType.BOOTSTRAP,
      AssistantDataType.COMMANDS,
    ],
  });

  const assistantRoutes: {
    path: string;
    Component: JSXElementConstructor<RouteComponentProps>;
  }[] = useMemo(() => {
    return [
      ...[
        { path: `${match.path}`, Component: Landing },
        { path: `${match.path}/commandcenter`, Component: Explore },
        { path: `${match.path}/notifications`, Component: Notifications },
        { path: `${match.path}/commands/internal`, Component: SummaryDetail },
        { path: `${match.path}/commands`, Component: Commands },
        { path: `${match.path}/todos`, Component: Todos },
        { path: `${match.path}/todos/:todoId`, Component: Todos },
        { path: `${match.path}/polls/answered`, Component: AnsweredPolls },
      ],
      //order of the elements matter, which is why there is this wierd structure of array spreads.
      ...(!notificationCenterEnabled
        ? [
            {
              path: `${match.path}/integrations/:integrationId`,
              Component: Integration,
            },
            {
              path: `${match.path}/service/:integrationId`,
              Component: Integration,
            },
          ]
        : []),
      ...[
        {
          path: `${match.path}/integrations/:integrationId/command/:command`,
          Component: CommandRunner,
        },
        {
          path: `${match.path}/service/:integrationId/command/:command`,
          Component: CommandRunner,
        },
        { path: `${match.path}/resources`, Component: Resources },
        { path: `${match.path}/contents`, Component: Contents },
        { path: `${match.path}/profiles`, Component: Profiles },
        { path: `${match.path}/channels`, Component: Channels },
      ],
    ];
  }, [match.path, notificationCenterEnabled]);

  return (
    <>
      <Curtain onClick={handleCloseClick} />

      <FocusLock group="assistant-lock" disabled={!!overlayIsActive}>
        <div
          className="assistant assistant__pane"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          role="dialog"
          aria-label={t('assistant.assistant')}
          aria-modal="true"
        >
          <NestedMemoryRouter matchPath={match.path} onRouteExit={onRouteExit}>
            <div className="assistant__main">
              {isError ? (
                <AssistantError />
              ) : (
                <Switch>
                  {assistantRoutes.map(({ path, Component }) => (
                    <Route
                      path={path}
                      render={(props) => (
                        <ErrorBoundary fallbackComponent={AssistantError}>
                          <Component {...props} />
                        </ErrorBoundary>
                      )}
                      exact
                      key={path}
                    />
                  ))}
                </Switch>
              )}
            </div>
            <aside className="assistant__nav">
              <AssistantNav />
            </aside>
          </NestedMemoryRouter>
        </div>
      </FocusLock>
    </>
  );
}
