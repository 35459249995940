import { mapFilterTagToIcon } from './filter-dropdown';
import { Tag } from '@socialchorus/shared-ui-components';
import styles from '../notification-center.module.scss';
import { NotificationFilterTags } from '../../../models/notifications/types';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { NotificationContext } from '../context';
import { mapTagsToVariant } from '../count-tag';

export const FilterTags = () => {
  const { t } = useTranslation();

  const {
    filterTags: [filterTags, setFilterTags],
  } = useContext(NotificationContext);

  return filterTags.length > 0 ? (
    <div className={styles.filterTagContainer}>
      {Object.values(NotificationFilterTags) //order of the tags should match the enum order
        .filter((tag) => filterTags.includes(tag))
        .map((tag) => (
          <Tag
            key={tag}
            compact
            variant={mapTagsToVariant(tag)}
            icon={mapFilterTagToIcon({ tag, compact: true })}
            onDelete={() => setFilterTags(filterTags.filter((f) => f !== tag))}
            label={t(`notification_center.tags.${tag}`)}
          />
        ))}
    </div>
  ) : null;
};
