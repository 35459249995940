import { useCallback, useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { FilterDropdown } from './filter-dropdown';
import {
  Button,
  Icon,
  IconButton,
  TooltipRoot,
  TooltipContent,
  TooltipTrigger,
  Toast,
  ToastActionButton,
} from '@socialchorus/shared-ui-components';
import styles from '../notification-center.module.scss';
import { useTranslation } from 'react-i18next';
import {
  NotificationCenterTabs,
  NotificationFilterTags,
} from '../../../models/notifications/types';
import { useBulkEdit } from './use-bulk-edit';
import { MobileOverlayKeys, NotificationContext } from '../context';
import { useDispatch } from 'react-redux';
import { ArchivePartialConfirmationModalID } from './archive-partial-confirmation-modal';
import { uiOperations } from '../../../models/ui';
import { TasksAlert } from './tasks-alert';
import { FilterTags } from './filter-tags';
import useScreenSize from '../../../common/use-screen-size';
import { SelectAllCheckbox } from './select-all-checkbox';
import { SearchInput } from './search-input';
import { SearchResultsText } from './search-results-text';

// this could be maybe better represented as a series of booleans, but i personally like the enum for readability. (maybe separate toolbar components for each mode?)
// trying to keep this all in the same component for now, its going to be easier to maintain search and filter context between them imo if we do. That could be wrong.
enum Modes {
  NORMAL = 'normal',
  ARCHIVE = 'archive',
  SEARCH = 'search',
}

type ToolbarProps = {
  body?: HTMLDivElement | null;
};

export const Toolbar: React.FC<ToolbarProps> = ({ body }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mode, setMode] = useState(Modes.NORMAL);
  const [filterOptions, setFilterOptions] = useState<
    NotificationFilterTags[] | undefined
  >();

  const {
    tabs: {
      currentTabs: [, setTabList],
      activeTab: [activeTab, setActiveTab],
    },
    multiSelect: {
      enabled: [multiSelectEnabled, setMultiSelectEnabled],
      selectAll: [, setSelectAll],
      selectedItems: [multiSelectItems, setMultiSelectItems],
    },
    search: {
      query: [searchQuery, setSearchQuery],
    },
    filterTags: [, setFilterTags],
    mobileOverlay: { open: openMobileOverlay },
  } = useContext(NotificationContext);

  const {
    items: {
      archivableItems,
      unArchivableItems,
      markableAsReadItems,
      markableAsUnreadItems,
      allSelectedItemsNotArchivable,
      someSelectedItemsNotArchivable,
    },
    actions: {
      archiveItems,
      unarchiveItems,
      markItemsAsRead,
      markItemsAsUnread,
    },
    success: {
      successMessage: bulkEditSuccessMessage,
      undoLastAction: bulkEditUndo,
      undoIsError: bulkEditUndoIsError,
    },
    error: {
      errorMessage: bulkEditErrorMessage,
      retryLastAction: bulkEditRetry,
      retryIsError: bulkEditRetryIsError,
    },
  } = useBulkEdit();

  const { isMobile } = useScreenSize();

  const showMarkArchiveButton =
    archivableItems.length > 0 ||
    (mode === Modes.NORMAL && allSelectedItemsNotArchivable);
  const showMarkUnArchiveButton = unArchivableItems.length > 0;
  const showMarkReadButton = markableAsReadItems.length > 0;
  const showMarkUnReadButton = markableAsUnreadItems.length > 0;

  const openArchiveConfirmationModal = useCallback(() => {
    const archivableCount = multiSelectItems.length - archivableItems.length;
    const totalCount = multiSelectItems.length;
    if (isMobile) {
      openMobileOverlay({
        screen: MobileOverlayKeys.ARCHIVE_PARTIAL_CONFIRMATION,
        props: {
          archivableCount,
          totalCount,
          onConfirm: archiveItems,
        },
      });
    } else {
      dispatch(
        uiOperations.displayOverlay(ArchivePartialConfirmationModalID, {
          archivableCount,
          totalCount,
          onConfirm: archiveItems,
        })
      );
    }
  }, [
    dispatch,
    archivableItems,
    multiSelectItems,
    archiveItems,
    isMobile,
    openMobileOverlay,
  ]);

  const onArchiveModeChange = useCallback(
    (enable: boolean) => {
      setMode(enable ? Modes.ARCHIVE : Modes.NORMAL);
      setFilterTags([]); //clear filters when swapping between archive/normal mode
      if (enable) {
        setSearchQuery(undefined);
      }
    },
    [setMode, setFilterTags, setSearchQuery]
  );

  const onViewAllTasksClick = useCallback(() => {
    setMode(Modes.NORMAL);
  }, [setMode]);

  useEffect(() => {
    if (!multiSelectEnabled) {
      setSelectAll(false);
      setMultiSelectItems([]);
    }
    setMultiSelectEnabled(multiSelectEnabled);
  }, [
    multiSelectEnabled,
    setMultiSelectEnabled,
    setSelectAll,
    setMultiSelectItems,
  ]);

  useEffect(() => {
    if (mode === Modes.ARCHIVE) {
      setTabList([NotificationCenterTabs.ARCHIVE]);
    } else if (multiSelectEnabled) {
      setTabList([activeTab]);
    } else {
      setTabList([
        NotificationCenterTabs.MESSAGES,
        NotificationCenterTabs.ACTIVITY,
      ]);
    }
  }, [setTabList, mode, activeTab, multiSelectEnabled]);

  //since this runs on every activeTab change, it is important to only modify it here when it is an invalid state.
  //this is mostly here to force the tabs component to have the only valid tab in the selected state when the validity list changes.
  useEffect(() => {
    if (mode === Modes.ARCHIVE) {
      //archive is the only valid tab in archive mode.
      setActiveTab(NotificationCenterTabs.ARCHIVE);
    } else if (activeTab === NotificationCenterTabs.ARCHIVE) {
      //archive cannot be an active tab in any other mode.
      setActiveTab(NotificationCenterTabs.MESSAGES);
    }
  }, [mode, activeTab, setActiveTab]);

  useEffect(() => {
    if (mode === Modes.ARCHIVE) {
      setFilterOptions([
        NotificationFilterTags.READ,
        NotificationFilterTags.UNREAD,
      ]);
    } else if (mode === Modes.NORMAL) {
      setFilterOptions([
        NotificationFilterTags.ACTION_REQUIRED,
        NotificationFilterTags.IMPORTANT,
        NotificationFilterTags.READ,
        NotificationFilterTags.UNREAD,
      ]);
    } else if (mode === Modes.SEARCH) {
      setFilterOptions([
        NotificationFilterTags.ACTION_REQUIRED,
        NotificationFilterTags.ARCHIVED,
        NotificationFilterTags.IMPORTANT,
        NotificationFilterTags.READ,
        NotificationFilterTags.UNARCHIVED,
        NotificationFilterTags.UNREAD,
      ]);
    }
  }, [mode]);

  useEffect(() => {
    if (searchQuery) {
      setMode(Modes.SEARCH);
    } else if (mode === Modes.SEARCH) {
      setMode(Modes.NORMAL);
    }
  }, [searchQuery, mode]);

  return (
    <>
      {isMobile && !multiSelectEnabled && mode === Modes.ARCHIVE && (
        <div className={cx(styles.toolbar, styles.contextModeEnabled)}>
          <Button
            variant="text"
            size="compact"
            label={t('common.back')}
            leftIcon={<Icon>arrow_back</Icon>}
            onClick={() => onArchiveModeChange(false)}
          />
        </div>
      )}

      <div
        className={cx(
          styles.toolbar,
          !isMobile &&
            mode == Modes.ARCHIVE &&
            !multiSelectEnabled &&
            styles.contextModeEnabled,
          multiSelectEnabled && styles.multiSelectEnabled
        )}
      >
        {!isMobile && mode === Modes.ARCHIVE && !multiSelectEnabled && (
          <Button
            variant="text"
            size="compact"
            label={t('common.back')}
            leftIcon={<Icon>arrow_back</Icon>}
            onClick={() => onArchiveModeChange(false)}
          />
        )}

        {multiSelectEnabled && !isMobile && <SelectAllCheckbox />}

        {!multiSelectEnabled ? (
          <SearchInput />
        ) : (
          <div className={styles.flexGrowSeachPlaceholder} />
        )}

        {/* Filter Button */}
        <div className={styles.buttonGroup}>
          <FilterDropdown container={body} allowedOptions={filterOptions} />
        </div>

        {/* Archive Mode Button */}
        {!isMobile && mode === Modes.NORMAL && !multiSelectEnabled && (
          <div className={styles.buttonGroup}>
            <TooltipRoot>
              <TooltipContent
                description={t('notification_center.view_archive')}
                sideOffset={5}
                container={body}
              />
              <TooltipTrigger asChild>
                <IconButton
                  label={t('notification_center.view_archive')}
                  iconName="inventory_2"
                  onClick={() => onArchiveModeChange(true)}
                />
              </TooltipTrigger>
            </TooltipRoot>
          </div>
        )}

        {/* Multi-Select Action Buttons */}
        {multiSelectEnabled && (
          <>
            {(showMarkArchiveButton || showMarkUnArchiveButton) && (
              <div className={styles.buttonGroup}>
                {showMarkArchiveButton && (
                  <TooltipRoot>
                    <TooltipContent
                      description={
                        allSelectedItemsNotArchivable
                          ? t(
                              'notification_center.archive_blocked_explaination'
                            )
                          : t('notification_center.move_to_archive')
                      }
                      sideOffset={5}
                      container={body}
                    />
                    <TooltipTrigger asChild>
                      <IconButton
                        label={t('notification_center.move_to_archive')}
                        iconName="inventory_2"
                        disabled={allSelectedItemsNotArchivable}
                        onClick={
                          someSelectedItemsNotArchivable
                            ? openArchiveConfirmationModal
                            : archiveItems
                        }
                      />
                    </TooltipTrigger>
                  </TooltipRoot>
                )}
                {showMarkUnArchiveButton && (
                  <TooltipRoot>
                    <TooltipContent
                      description={t('notification_center.remove_from_archive')}
                      sideOffset={5}
                      container={body}
                    />
                    <TooltipTrigger asChild>
                      <IconButton
                        label={t('notification_center.remove_from_archive')}
                        iconName="unarchive"
                        onClick={unarchiveItems}
                      />
                    </TooltipTrigger>
                  </TooltipRoot>
                )}
              </div>
            )}
            {(showMarkReadButton || showMarkUnReadButton) && (
              <div className={styles.buttonGroup}>
                {showMarkReadButton && (
                  <TooltipRoot>
                    <TooltipContent
                      description={t('notification_center.mark_as_read')}
                      sideOffset={5}
                      container={body}
                    />
                    <TooltipTrigger asChild>
                      <IconButton
                        label={t('notification_center.mark_as_read')}
                        className={styles.customIconButton}
                        iconName="drafts"
                        onClick={markItemsAsRead}
                      />
                    </TooltipTrigger>
                  </TooltipRoot>
                )}
                {showMarkUnReadButton && (
                  <TooltipRoot>
                    <TooltipContent
                      description={t('notification_center.mark_as_unread')}
                      sideOffset={5}
                      container={body}
                    />
                    <TooltipTrigger asChild>
                      <IconButton
                        label={t('notification_center.mark_as_unread')}
                        className={styles.customIconButton}
                        iconName="mark_email_unread"
                        onClick={markItemsAsUnread}
                      />
                    </TooltipTrigger>
                  </TooltipRoot>
                )}
              </div>
            )}
          </>
        )}

        {/* Multiselect Button / Back Button */}
        {!multiSelectEnabled ? (
          !(isMobile && mode === Modes.NORMAL) && (
            <div className={styles.buttonGroup}>
              <TooltipRoot>
                <TooltipContent
                  description={t('notification_center.enable_multi_select')}
                  sideOffset={5}
                  container={body}
                />
                <TooltipTrigger asChild>
                  <IconButton
                    iconName="edit_note"
                    onClick={() => setMultiSelectEnabled(true)}
                  />
                </TooltipTrigger>
              </TooltipRoot>
            </div>
          )
        ) : (
          <div className={styles.buttonGroup}>
            <Button
              variant="text"
              size="compact"
              label={t('common.done')}
              onClick={() => setMultiSelectEnabled(false)}
            />
          </div>
        )}

        {/* Mobile Extra Actions */}
        {isMobile && mode === Modes.NORMAL && (
          <div className={styles.buttonGroup}>
            <TooltipRoot>
              <TooltipContent
                description={t('common.more')}
                sideOffset={5}
                container={body}
              />
              <TooltipTrigger asChild>
                <IconButton
                  label={t('common.more')}
                  iconName="more_vert"
                  onClick={() =>
                    openMobileOverlay({
                      screen: MobileOverlayKeys.TOOLBAR_EXTRA_OPTIONS,
                      props: {
                        onArchiveClick: () => onArchiveModeChange(true),
                        onEnableMultiSelectClick: () =>
                          setMultiSelectEnabled(true),
                      },
                    })
                  }
                />
              </TooltipTrigger>
            </TooltipRoot>
          </div>
        )}
      </div>

      <FilterTags />

      <SearchResultsText />

      {multiSelectEnabled && isMobile && (
        <div className={styles.toolbar}>
          <SelectAllCheckbox />
        </div>
      )}

      <TasksAlert onViewAllTasksClick={onViewAllTasksClick} />

      {bulkEditSuccessMessage && (
        <Toast
          title={bulkEditSuccessMessage}
          defaultOpen
          actionButton={
            bulkEditUndo && (
              <ToastActionButton
                label={t('notification_center.undo')}
                altText={'notification_center.undo_verbose'}
                onClick={bulkEditUndo}
              />
            )
          }
        />
      )}
      {bulkEditErrorMessage && (
        <Toast
          title={bulkEditErrorMessage}
          variant="error"
          defaultOpen
          actionButton={
            bulkEditRetry && (
              <ToastActionButton
                label={t('notification_center.retry')}
                altText={'notification_center.retry_verbose'}
                onClick={bulkEditRetry}
              />
            )
          }
        />
      )}
      {bulkEditUndoIsError && (
        <Toast
          title={t('notification_center.undo_error_message')}
          variant="error"
          defaultOpen
        />
      )}
      {bulkEditRetryIsError && (
        <Toast
          title={t('notification_center.retry_error_message')}
          variant="error"
          defaultOpen
        />
      )}
    </>
  );
};
