import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import get from 'lodash/get';
import isObject from 'lodash/isObject';

import {
  AssistantCommandSelector,
  AssistantDialogue,
  AssistantBackBar,
} from '../../components/assistant';
import useCommandRunner from '../../common/use-command-runner';
import { trackAssistantDialogLoad } from '../../models/assistant/analytics';
import styles from './assistant.module.scss';

const AssistantHistory = ({
  location,
  history,
  match,
}: RouteComponentProps) => {
  const [showGoBack, setShowGoBack] = useState(false);
  const { runCommand } = useCommandRunner();

  useEffect(() => {
    if (isObject(location.state)) {
      const command = get(location.state, 'command');

      setShowGoBack(Boolean(command || get(location.state, 'showBack')));

      if (command) {
        history.replace({
          pathname: location.pathname,
          state: { showBack: true },
        });
        runCommand(command, get(location.state, 'analyticsData'));
        setShowGoBack(true);
        trackAssistantDialogLoad({
          location: 'deeplink',
        });
      }
    } else if (!location.state) {
      trackAssistantDialogLoad();
    }
  }, [location.state, location.pathname, match.params, history, runCommand]);

  return (
    <div className="assistant__commands assistant__search-view">
      <div className={styles.body}>
        {showGoBack ? <AssistantBackBar /> : null}
        <AssistantDialogue
          onSelect={(command) => {
            runCommand(command, { location: 'suggested_followup' });
          }}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.inputContainer}>
          <AssistantCommandSelector
            onSelect={(command) => {
              runCommand(command, {
                location: 'command_search',
                ...command.tracking_context,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AssistantHistory;
