import { useCallback, useContext, useMemo } from 'react';
import { Checkbox } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../context';
export const SelectAllCheckbox = () => {
  const { t } = useTranslation();

  const {
    tabs: {
      activeTab: [activeTab],
      tabTotals: [tabTotals],
    },
    multiSelect: {
      selectAll: [selectAll, setSelectAll],
      selectedItems: [multiSelectItems, setMultiSelectItems],
    },
    search: {
      query: [searchQuery],
      tabResultCounts: [searchTabResultCounts],
    },
  } = useContext(NotificationContext);

  const selectAllLabel = useMemo(() => {
    if (
      selectAll === true &&
      multiSelectItems.length === tabTotals[activeTab]
    ) {
      return t('common.selected_all_with_count', {
        count: multiSelectItems.length,
      });
    } else if (multiSelectItems.length > 0) {
      const total = searchQuery
        ? searchTabResultCounts[activeTab]
        : tabTotals[activeTab];
      return t('common.selected_partial_with_count', {
        selected: multiSelectItems.length,
        total,
      });
    } else return t('common.select_all');
  }, [
    selectAll,
    multiSelectItems,
    tabTotals,
    activeTab,
    searchQuery,
    searchTabResultCounts,
    t,
  ]);

  const onSelectAllChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectAll(true);
      } else {
        setMultiSelectItems([]);
        setSelectAll(false);
      }
    },
    [setSelectAll, setMultiSelectItems]
  );

  return (
    <Checkbox
      label={selectAllLabel}
      checked={selectAll}
      onCheckedChange={onSelectAllChange}
    />
  );
};
