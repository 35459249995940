import { BoxItem } from '@socialchorus/box-components';
import { AxiosResponse } from 'axios';
import MojoApiFactory from '../config/api-factory';

type FetchUserTokenResult = {
  program_id: number;
  firstup_user_id: number;
  box_user_id: number;
  token: string;
  token_expires_at: string;
};

export function fetchUserToken() {
  return MojoApiFactory.v3.get<FetchUserTokenResult>('/box_integration/tokens');
}

/**
 * Fetches the downscoped token specific for the given box object
 */
export function fetchBoxObjectToken(boxObjectId: string) {
  return MojoApiFactory.v3.get<{ access_token: string; expires_in: number }>(
    `/box_integration/box_objects/${boxObjectId}/token`
  );
}

export function filterBoxResponseByPermission(
  boxResponse: AxiosResponse<BoxItem>
) {
  return MojoApiFactory.v3.post<
    AxiosResponse<BoxItem>,
    AxiosResponse<AxiosResponse<BoxItem>>
  >('/box_integration/permissions/filter', boxResponse);
}

export function hasAccessToBoxFolder(boxFolderId: string) {
  return MojoApiFactory.v3.get(
    `/box_integration/folders/${boxFolderId}/has_access`
  );
}

export function getBoxRootFolder() {
  return MojoApiFactory.v3.get<{ root_folder_id: number }>(
    `/box_integration/root_folder`
  );
}
