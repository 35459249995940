import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  IconButton,
  TextInput,
} from '@socialchorus/shared-ui-components';
import { NotificationContext } from '../context';
import styles from '../notification-center.module.scss';

export const SearchInput = () => {
  const { t } = useTranslation();

  const {
    search: {
      query: [searchQuery, setSearchQuery],
    },
  } = useContext(NotificationContext);

  //value must be a string, as passing in undefined as a value does not set the input to be empty. We need to pass in an empty string to clear the input.
  //as a result, considering '' and undefined as the same value.
  //(maybe this should be how the global value works as well? I didnt do it that way as this contraint is specifically a quirk of this component)
  const [_searchQuery, _setSearchQuery] = useState<string>(searchQuery || '');

  useEffect(() => {
    const updateGlobal = setTimeout(() => {
      if (_searchQuery === '') {
        setSearchQuery(undefined);
      } else setSearchQuery(_searchQuery);
    }, 200);

    return () => clearTimeout(updateGlobal);
  }, [_searchQuery, setSearchQuery]);

  useEffect(() => {
    _setSearchQuery(searchQuery || '');
  }, [searchQuery]);

  return (
    <TextInput
      fullWidth
      leftWidget={<Icon size={16}>search</Icon>}
      rightWidget={
        <IconButton
          size="compact"
          iconName="close"
          onClick={() => {
            _setSearchQuery('');
            setSearchQuery(undefined);
          }}
          className={styles.searchCloseButton}
        />
      }
      size="compact"
      placeholder={t('notification_center.search_placeholder')}
      onChange={(query) => _setSearchQuery(query)}
      value={_searchQuery}
      role="search"
    />
  );
};
