import { useTranslation } from 'react-i18next';
import { Button, Icon } from '../../ui';
import { useRef } from 'react';

interface CommentAttachmentButtonProps {
  onFileSelect: (file: File) => void;
}

const CommentAttachmentButton = ({
  onFileSelect,
}: CommentAttachmentButtonProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const files = event.target.files;
    if (files && files[0]) {
      onFileSelect(files[0]);
      event.target.value = '';
    }
  };

  const handleButtonClick = (): void => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/jpeg, image/png"
        id="comment-attachment-input"
        type="file"
        onChange={handleFileChange}
      />
      <Button
        type="button"
        title={t('comments.actions.attachments')}
        onClick={handleButtonClick}
      >
        <Icon type="photo" />
      </Button>
    </>
  );
};

export default CommentAttachmentButton;
