import { useContext } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../context';
import style from '../notification-center.module.scss';

export const SearchResultsText = () => {
  const { t } = useTranslation();
  const {
    search: {
      query: [searchQuery],
      tabResultCounts: [tabResultCounts],
    },
    tabs: {
      activeTab: [activeTab],
    },
  } = useContext(NotificationContext);

  const count = tabResultCounts[activeTab];

  return (
    <div className={cx(style.searchResultsText, 'text-caption')}>
      {searchQuery ? (
        <span
          dangerouslySetInnerHTML={{
            __html: t('notification_center.search.x_results_for_query', {
              query: searchQuery,
              count,
            }),
          }}
        ></span>
      ) : null}
    </div>
  );
};
