import { Monitor } from './types';

export default class ArticleMonitor implements Monitor {
  private attachedElement?: HTMLIFrameElement;
  private observer?: IntersectionObserver;
  private sentinel?: HTMLElement;
  private completed = false;
  private loadHandler?: () => void;

  constructor(readonly onCompleteCallback: (monitor: ArticleMonitor) => void) {}

  attach(el: HTMLIFrameElement) {
    // Clean up any previous attachment.
    if (this.attachedElement) {
      this.detach();
    }
    this.attachedElement = el;

    // This function will create the sentinel and setup the observer.
    const setupObserver = () => {
      // Remove the load event listener (if it was added) so it doesn't fire more than once.
      el.removeEventListener('load', this.loadHandler!);

      // Create a small sentinel element.
      this.sentinel = document.createElement('div');
      this.sentinel.className = 'article-sentinel';
      this.sentinel.style.width = '100%';
      this.sentinel.style.height = '1px';
      this.sentinel.style.marginBottom = '5px';
      this.sentinel.style.pointerEvents = 'none';

      // Insert the sentinel right after the iframe.
      if (el.parentNode) {
        el.parentNode.insertBefore(this.sentinel, el.nextSibling);
      }

      // Create an IntersectionObserver to monitor when the sentinel enters the viewport.
      this.observer = new IntersectionObserver(
        (entries) => this.handleIntersection(entries),
        {
          // Using threshold 1.0 ensures the entire sentinel is visible.
          threshold: 1.0,
        }
      );

      if (this.sentinel) {
        this.observer.observe(this.sentinel);
      }
    };

    // Save the function reference for removal later if needed.
    this.loadHandler = setupObserver;

    // Check if the iframe's content is already loaded.
    if (
      el.contentWindow &&
      el.contentWindow.document.readyState === 'complete'
    ) {
      setupObserver();
    } else {
      el.addEventListener('load', this.loadHandler);
    }
  }

  detach() {
    // Remove the load event listener if it hasn't fired yet.
    if (this.attachedElement && this.loadHandler) {
      this.attachedElement.removeEventListener('load', this.loadHandler);
    }

    // Disconnect the observer and remove the sentinel.
    if (this.observer && this.sentinel) {
      this.observer.unobserve(this.sentinel);
      this.observer.disconnect();
    }
    if (this.sentinel && this.sentinel.parentNode) {
      this.sentinel.parentNode.removeChild(this.sentinel);
    }

    // Reset all instance properties.
    this.attachedElement = undefined;
    this.sentinel = undefined;
    this.observer = undefined;
    this.completed = false;
    this.loadHandler = undefined;
  }

  private handleIntersection(entries: IntersectionObserverEntry[]) {
    for (const entry of entries) {
      if (entry.isIntersecting && !this.completed) {
        this.completed = true;
        this.onCompleteCallback(this);
      }
    }
  }
}
