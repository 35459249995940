import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'focus-within-polyfill';
import 'vite/modulepreload-polyfill';

import './config/polyfills';
import './config/datadog';

import '@socialchorus/shared-ui-components/style';

import ReactDOM from 'react-dom';
import api from './config/api';
import apiFactory from './config/api-factory';
import eva from './config/eva';
import twig from './config/twig';

import Patron from './patron';

import * as serviceWorker from './serviceWorker';

import './config/i18n';
import NamespacedLocalStorage from './lib/localstorage-namespaced';
import { localStorageEnabled, removeStorageItem } from './lib/localstorage';
import { optimus } from './components/v2/search/http';

const getSessionData = () => {
  return api.post('/auth').then((res) => res.data);
};

const getBootstrapData = () => {
  const patronDefaultHostname = import.meta.env.VITE_APP_PATRON_DOMAIN;
  const customDomain = window.location.hostname !== patronDefaultHostname;

  if (customDomain) {
    return api.get('/bootstrap-data');
  } else {
    const matches = /^\/(?<brandSlug>[^/]*)\/(?<programSlug>[^/]*)\/?/.exec(
      window.location.pathname
    );

    if (matches && matches.groups) {
      return api.get(
        `/${matches.groups.brandSlug}/${matches.groups.programSlug}/bootstrap-data`
      );
    }
    return Promise.reject('Unable to parse program slugs from url');
  }
};

const processBootstrapData = (sessionData: unknown) =>
  getBootstrapData().then((res) => ({
    session: sessionData,
    ...res.data,
  }));

const setRequestDefaults = (bootstrapData: any) => {
  const {
    config: {
      api_url: apiUrl = undefined,
      eva_url: evaUrl = undefined,
      twig_url: twigUrl = undefined,
      optimus_url: optimusUrl = undefined,
    } = {},
    session: { session_id: sessionId = undefined } = {},
    program: { id: programId = undefined } = {},
  } = bootstrapData;

  const authHeaders = {
    Authorization: `SocialChorusAuth sessionId='${sessionId}'`,
    'x-api-version': '3.5.0',
    'x-sc-platform': 'web',
  };

  api.defaults.baseURL = `${apiUrl}/v2/programs/${programId}`;
  api.defaults.headers.common = authHeaders;

  apiFactory.apiUrl = apiUrl;
  apiFactory.programId = programId;
  apiFactory.authHeaders = authHeaders;

  eva.defaults.baseURL = `${evaUrl}/v1/programs/${programId}`;
  eva.defaults.headers.common = authHeaders;

  twig.defaults.baseURL = twigUrl;

  optimus.defaults.baseURL = optimusUrl;
  optimus.defaults.headers.common = authHeaders;

  return bootstrapData;
};

const processNamespacedLocalStorage = (bootstrapData: any) => {
  if (!localStorageEnabled()) return bootstrapData;

  const {
    session: { advocate_id, program_id },
  } = bootstrapData;

  const namespacedStorage = new NamespacedLocalStorage({
    advocate_id: String(advocate_id),
    program_id: String(program_id),
  });

  Object.keys(window.localStorage)
    .filter(
      (key) =>
        NamespacedLocalStorage.isNamespacedEntry(key) &&
        !namespacedStorage.isKeyAccessible(key)
    )
    .forEach(removeStorageItem);

  return bootstrapData;
};

const mountApp = (bootstrapData: any) => {
  ReactDOM.render(
    <Patron bootstrapData={bootstrapData} />,
    document.getElementById('patron')
  );
};

getSessionData()
  .then(processBootstrapData)
  .then(setRequestDefaults)
  .then(processNamespacedLocalStorage)
  .then(mountApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
