import { Feature } from '../models/features/features';
import useFeatureFlag from './use-feature-flag';
import useHeaderMinimizer from './use-header-minimizer';

export const useContentHeaderMinimizer = ({
  loadSuccess,
}: {
  loadSuccess: boolean;
}) => {
  const backButtonDisabled = useFeatureFlag(
    Feature.CONTENT_BACK_BUTTON_REMOVED
  );
  //for amazon, they want to force the header to be hidden even with the back button removed.
  //related to their desire to use the content detail page as a standalone landing page without being embedded, but also use the site normally for other users/departments
  //if this keeps being an issue, lets give them a new url to use for content detail.
  const forceHideHeader = useFeatureFlag(Feature.CONTENT_DETAIL_HIDE_HEADER);

  useHeaderMinimizer(!!forceHideHeader || (!backButtonDisabled && loadSuccess));
};
