import { Author } from '../assistant/interfaces/Author';

export type CommentActionSuccess = {
  message: string;
};

export type CommentPostResponse = {
  data: {
    id: string;
    type: string;
    attributes: {
      id: string;
      isEdited: boolean;
      userId: number;
      contentId: number;
      discourseId: number;
      programId: number;
      content: string;
      rawContent: string;
      preTranslationContent: string;
      replyToId: string | null;
      createdAt: string;
      updatedAt: string;
      deletedAt: string | null;
      highlightedAt: string | null;
      language: string;
      replyCount: number;
      isLiked: boolean;
      likeCount: number;
      canEdit: boolean;
      canDelete: boolean;
      canReport: boolean;
      canHighlight: boolean;
      canLike: boolean;
      isReported: boolean;
      author: {
        id: string;
        avatar: {
          url: string;
          color: string;
        };
        name: string;
        privateProfile: boolean;
      };
      isTranslatable: boolean;
      displayLanguage: string;
      attachments?: CommentAttachment[];
    };
  };
};
export type CommentAttachment = {
  url: string;
  altText: string;
};

export type CommentPostBatchResponse = {
  data: CommentPostResponse['data'][];
  meta: CommentPostResponseMeta;
};

export type CommentPostResponseMeta = {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
};

export type Comment = CommentPostResponse['data']['attributes'] & {
  translationInProgress?: boolean;
};

export type CommentWithReplies = Comment & {
  replies: (Comment | CommentWithReplies)[];
  repliesPageCount: number;
  repliesCurrentPage: number;
};

export function isCommentWithReplies(
  comment: Comment | CommentWithReplies
): comment is CommentWithReplies {
  return (
    'replies' in comment &&
    'repliesPageCount' in comment &&
    'repliesCurrentPage' in comment
  );
}

export type CommentAssistantSuggestionType =
  | 'improve_writing'
  | 'fix_spelling_grammar'
  | 'use_concise_language'
  | 'change_tone';
export type CommentAssistantSuggestionSubtype =
  | 'professional'
  | 'casual'
  | 'straightforward'
  | 'confident'
  | 'friendly';

export type CommentAssistantSuggestionSelection =
  | {
      suggestionType: Exclude<CommentAssistantSuggestionType, 'change_tone'>;
    }
  | {
      suggestionType: Extract<CommentAssistantSuggestionType, 'change_tone'>;
      suggestionSubtype: CommentAssistantSuggestionSubtype;
    };
