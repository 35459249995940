import types from './types.js';
import utils from './utils.js';

const initialState = {
  contents: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ACKNOWLEDGE:
      return utils.acknowledgeContent(state, action.id);

    case types.ACKNOWLEDGE_COMPLETE:
      return utils.updateContent(state, action.id, action.attributes);

    case types.UNACKNOWLEDGE:
      return utils.unacknowledgeContent(state, action.id);

    case types.COMPLETED:
      return utils.updateContent(state, action.id, { is_completed: true });

    case types.ADD:
      return utils.addContent(state, action.content);

    case types.ADD_BULK:
      return utils.addContents(state, action.contents);

    case types.LIKE:
      return utils.likeContent(state, action.id);

    case types.UNLIKE:
      return utils.unlikeContent(state, action.id);

    case types.BOOKMARK:
      return utils.updateContent(state, action.id, { bookmarked: true });

    case types.UNBOOKMARK:
      return utils.updateContent(state, action.id, { bookmarked: false });

    case types.SHARE:
      return utils.updateContent(state, action.id, { shared: true });

    case types.UNSHARE:
      return utils.updateContent(state, action.id, { shared: false });

    case types.INCREMENT_COMMENT_COUNT:
      return utils.updateContentCommentsCount(state, action.id, 1);

    case types.DECREMENT_COMMENT_COUNT: {
      const by = action.by || 1;
      return utils.updateContentCommentsCount(state, action.id, -by);
    }
    //escape hatch to stop having to use redux patterns and all the boilerplate that comes with it
    //if you just want to update the state, use this.
    case types.UPDATE: {
      return utils.updateContent(state, action.id, action.attributes);
    }
    default:
      return state;
  }
};

export default reducer;
