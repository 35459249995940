import React, { ReactNode, useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import ReactFocusLock from 'react-focus-lock';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@socialchorus/shared-ui-components';

import { Curtain } from '../../components/ui';
import { usePatronSelector } from '../../common/use-patron-selector';
import { uiSelectors } from '../../models/ui';
import { setPageScroll } from '../../patron-routes';
import styles from './assistant-side-panel.module.scss';

const ANIMATION_DURATION = 200;

export type AssistantSidePanelProps = RouteComponentProps & {
  goBack: {
    pathname?: string;
    search?: string;
    hash?: string;
    state?: unknown;
  } | null;
  children?: ReactNode;
};

export const AssistantSidePanel: React.FC<AssistantSidePanelProps> = ({
  history,
  goBack,
  children,
}) => {
  const { t } = useTranslation();

  const [closing, setClosing] = useState(false);
  const overlayIsActive = usePatronSelector((state) =>
    uiSelectors.getOverlayIsActive(state)
  );

  const close = useCallback(() => {
    goBack?.pathname ? history.replace(goBack.pathname) : history.goBack();
  }, [history, goBack?.pathname]);

  const handleCloseClick = useCallback(() => {
    setClosing(true);
    setTimeout(() => {
      close();
    }, ANIMATION_DURATION);
  }, [close]);

  React.useEffect(() => {
    if (closing) {
      setPageScroll(true);
    }
  }, [closing]);

  return (
    <>
      <Curtain
        onClick={handleCloseClick}
        className="assistant-side-panel"
        enabled={!closing}
      />
      <ReactFocusLock disabled={!!overlayIsActive}>
        <div
          className={cx(
            styles.assistantSidePanel,
            closing && styles.closing,
            'assistant'
          )}
        >
          <div className={styles.header}>
            <div className={cx('semibold text-tertiary-heading', styles.title)}>
              {t('assistant.assistant')}
            </div>
            <IconButton iconName="close" onClick={handleCloseClick} />
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </ReactFocusLock>
    </>
  );
};
