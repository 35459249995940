import { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import Modal from '../../../ui/modal';
import { Button, Icon, TextInput } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import './attachment-modal.scss';
import useScreenSize from '../../../../common/use-screen-size';
import Spinner from '../../../ui/spinner';
import useHostedMediaUploader from '../../../../common/use-hosted-media-uploader';
import { useProgram } from '../../../../common/use-program';
import { CommentAttachment } from '../../../../models/comments/types';

export const ID = 'Overlays.ATTACHMENT_MODAL';
export type AttachmentModalProps = {
  action: 'add' | 'edit' | 'view';
  close: () => void;
  file?: File;
  existingAttachment?: CommentAttachment;
  onAttachmentRemoved: (url: string) => void;
  onAttachmentSaved: (attachment: CommentAttachment) => void;
  onError: (err: Error) => void;
};

const AttachmentModal = ({
  action,
  close,
  file,
  existingAttachment,
  onAttachmentSaved,
  onAttachmentRemoved,
  onError,
}: AttachmentModalProps) => {
  const { isMobile } = useScreenSize();
  const { id: programId } = useProgram();
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>(existingAttachment?.url || '');
  const [altText, setAltText] = useState<string>(
    existingAttachment?.altText || ''
  );
  const [formFile, setFormFile] = useState<File | null>(file || null);
  const handleSave = useCallback(
    (attachment: CommentAttachment) => {
      if (!attachment.url) return;
      onAttachmentSaved(attachment);
      close();
    },
    [onAttachmentSaved, close]
  );

  useHostedMediaUploader({
    file: formFile,
    onComplete: (url: string) => {
      setUrl(url);
    },
    onError: (err: Error) => {
      onError(err);
      close();
    },
    programId: programId,
  });

  const onFileChange = (file: File) => {
    setFormFile(file);
    setUrl('');
  };

  const onRemove = (url: string) => {
    onAttachmentRemoved(url);
    close();
  };

  return (
    <Modal
      lockScroll
      onClose={close}
      className="attachment-modal"
      showSiteHeader={isMobile}
    >
      <header className="attachment-modal__header">
        <div className="attachment-modal__header--headings">
          <div
            className={cx(
              isMobile ? 'text-main-heading' : 'text-tertiary-heading'
            )}
          >
            {action === 'add'
              ? t('comments.image_modal.add')
              : action === 'edit'
              ? t('comments.image_modal.edit')
              : ''}
            {action === 'view' && isMobile && (
              <>
                <Button
                  onClick={close}
                  variant="text"
                  label={t('common.close')}
                  leftIcon={<Icon>arrow_back</Icon>}
                />
              </>
            )}
          </div>
        </div>
      </header>
      {action === 'view' && isMobile && (
        <div className="attachment-modal__header--divider" />
      )}
      <div className="attachment-modal__body">
        <div className="attachment-modal__body--image">
          {url ? <ImagePreview url={url} /> : <ImageLoading />}
        </div>
        {action !== 'view' && (
          <AttachmentForm
            url={url}
            altText={altText}
            setAltText={setAltText}
            onFileChange={onFileChange}
            onAttachmentRemoved={onRemove}
          />
        )}
      </div>

      {action !== 'view' && (
        <footer className="attachment-modal__footer">
          <div className="attachment-modal__footer--actions">
            <Button
              onClick={close}
              variant="outline"
              label={t('common.cancel')}
            />
            <Button
              onClick={() => handleSave({ url, altText })}
              variant="primary"
              disabled={!url}
              label={t('comments.image_modal.add')}
              isLoading={!url}
            />
          </div>
        </footer>
      )}
    </Modal>
  );
};

const AttachmentForm = ({
  url,
  altText,
  setAltText,
  onFileChange,
  onAttachmentRemoved,
}: {
  url: string | null;
  altText: string;
  setAltText: (text: string) => void;
  onFileChange: (file: File) => void;
  onAttachmentRemoved: (url: string) => void;
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const files = event.target.files;
    if (files && files[0]) {
      onFileChange(files[0]);
      event.target.value = '';
    }
  };

  const handleRemove = () => {
    if (url) {
      onAttachmentRemoved(url);
    }
  };

  return (
    <>
      <div className="attachment-modal__body--image--actions">
        <Button
          onClick={handleRemove}
          variant="text"
          label={t('comments.image_modal.remove')}
          leftIcon={<Icon>delete</Icon>}
          disabled={!url}
        />
        <input
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/jpeg, image/png"
          id="comment-attachment-input"
          type="file"
          onChange={handleFileChange}
          disabled={!url}
        />
        <Button
          onClick={() => fileInputRef.current?.click()}
          variant="text"
          label={t('comments.image_modal.replace')}
          leftIcon={<Icon>photo</Icon>}
          disabled={!url}
        />
      </div>
      <TextInput
        id="alt-text"
        label={t('comments.image_modal.alt_text') || ''}
        placeholder={t('comments.image_modal.alt_text' || '')}
        size="default"
        fullWidth
        value={altText || ''}
        onChange={(text: string) => setAltText(text)}
      />
      <div className="attachment-modal__body--alt-text-caption">
        {t('comments.image_modal.alt_text_caption')}
      </div>
    </>
  );
};

const ImageLoading = () => {
  return (
    <div className="attachment-modal__body--image--loading">
      <Spinner />
      Loading
    </div>
  );
};

const ImagePreview = ({ url }: { url: string }) => {
  return <img src={url} alt="image preview" />;
};

export default AttachmentModal;
//
