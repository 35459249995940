import {
  BoxContentExplorer,
  BoxItem,
  useResponseInterceptor,
} from '@socialchorus/box-components';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  MemoryRouter,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Spinner } from '../../../components/ui/spinner';
import { trackBoxKmExplorerOpen } from '../../../models/box-integration/analytics';
import { uiOperations } from '../../../models/ui';
import { filterBoxResponseByPermission } from '../../../services/box-integration';
import {
  BoxTokenError,
  BoxUserFolderAccessError,
  useBoxToken,
} from '../useBoxToken';
import styles from './styles.module.scss';

type RouteParams = {
  folderId: string;
};

export function ContentExplorer() {
  const { folderId } = useParams<RouteParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const forbiddenErrorMessage = t('screens.box.error.forbidden', {
    object: t('common.folder'),
  });
  const notFoundErrorMessage = t('screens.box.error.not_found', {
    object: t('common.folder'),
  });

  const onBoxTokenError = useCallback(
    (err: unknown) => {
      if (err instanceof BoxTokenError) {
        history.replace('/box/error', {
          errorMessage:
            err.status === 403 ? forbiddenErrorMessage : notFoundErrorMessage,
        });
      } else if (err instanceof BoxUserFolderAccessError) {
        history.replace('/box/error', {
          errorMessage: forbiddenErrorMessage,
        });
      }
      throw err;
    },
    [forbiddenErrorMessage, history, notFoundErrorMessage]
  );

  const { token, refetch } = useBoxToken({
    resourceType: 'folder',
    resourceId: folderId,
    onError: onBoxTokenError,
  });

  const permissionFilter = useCallback(
    async (
      response: AxiosResponse<BoxItem>
    ): Promise<AxiosResponse<BoxItem>> => {
      if (response.status === 404) {
        history.replace('/box/error', { errorMessage: notFoundErrorMessage });
      }

      if (response.status % 200 < 100) {
        return (await filterBoxResponseByPermission(response)).data;
      }
      return response;
    },
    [history, notFoundErrorMessage]
  );

  const refetchToken = useCallback(
    async (response: AxiosResponse) => {
      const tokenData = await refetch();

      if (!tokenData) {
        return response;
      }

      return axios.request({
        ...response.config,
        headers: {
          ...response.config.headers,
          Authorization: `Bearer ${tokenData.token}`,
        },
      });
    },
    [refetch]
  );

  const notFound = useCallback(
    async (response: AxiosResponse) => {
      history.replace('/box/error', { errorMessage: notFoundErrorMessage });
      return response;
    },
    [history, notFoundErrorMessage]
  );

  const responseInterceptor = useResponseInterceptor({
    permissionFilter,
    refetchToken,
    notFound,
  });

  useEffect(() => {
    dispatch(uiOperations.hideHeader());

    return () => {
      dispatch(uiOperations.showHeader());
    };
  }, [dispatch]);

  useEffect(() => {
    trackBoxKmExplorerOpen({
      folder_id: folderId,
    });
  }, [folderId]);

  return (
    <div className={styles.ContentExplorerContainer}>
      {!token ? (
        <Spinner />
      ) : (
        <MemoryRouter initialEntries={[location]} initialIndex={0}>
          <BoxContentExplorer
            folderId={folderId}
            token={token}
            canCreateNewFolder={false}
            canDelete={false}
            canDownload={false}
            canRename={false}
            canUpload={false}
            canShare={false}
            canSetShareAccess={false}
            responseInterceptor={responseInterceptor}
          />
        </MemoryRouter>
      )}
    </div>
  );
}
