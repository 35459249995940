import {
  PopoverRoot,
  PopoverAnchor,
  PopoverTrigger,
  PopoverContent,
  DateRangePicker,
  Icon,
} from '@socialchorus/shared-ui-components';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useDateRangeQueryParams } from '../../query-params';
import { FilterBarButton, RemoveFilterButton } from '../filter-bar';
import { SearchFilter } from './filters';
import {
  DropdownFilter,
  DropdownFilterTrigger,
  DropdownFilterContent,
  DropdownFilterItem,
} from '../primitives/dropdown-filter';
import {
  parseDate,
  today,
  getLocalTimeZone,
  CalendarDate,
} from '@internationalized/date';
import styles from '../filter-bar.module.scss';

type DateOptions = {
  name: string;
  from?: string;
  to?: string;
};
/**
 * DateRangeableFilter represents a filter that has a date range picker to choose from.
 */
export type DateRangeableFilter = SearchFilter<{
  behaviour: 'daterangeable';
  parameters: string[];
  options: DateOptions[];
}>;

type DateRangeableFilterProps = {
  filter: DateRangeableFilter;
  handleSelect: (parameter: string, value: string) => void;
};

type State =
  | {
      state: 'open';
      dropdownOpen: true;
      datePickerPopoverOpen: boolean;
    }
  | {
      state: 'closed';
      dropdownOpen: false;
      datePickerPopoverOpen: false;
    }
  | {
      state: 'datepicker-closed';
      dropdownOpen: boolean;
      datePickerPopoverOpen: false;
    }
  | {
      state: 'datepicker-open';
      dropdownOpen: true;
      datePickerPopoverOpen: true;
    };

type Action =
  | { type: 'open-dropdown' }
  | { type: 'close-dropdown' }
  | { type: 'interact-outside'; eventType: Event['type'] }
  | { type: 'toggle-datepicker' };

const POINTER_DOWN_OUTSIDE = 'dismissableLayer.pointerDownOutside';

function useVisibleDropdowns() {
  const [state, dispatch] = React.useReducer(
    (state: State, action: Action) => {
      switch (action.type) {
        case 'open-dropdown':
          return open();
        case 'close-dropdown':
          return close();
        case 'interact-outside':
          if (
            (state.datePickerPopoverOpen || state.dropdownOpen) &&
            action.eventType === POINTER_DOWN_OUTSIDE
          ) {
            return close();
          }
          return state;
        case 'toggle-datepicker':
          return state.datePickerPopoverOpen
            ? closeDatePicker()
            : openDatePicker();

        default:
          return state;
      }

      /**
       * -- Open Dropdown --
       * Open the options dropdown list. This is the first state when the filter is clicked.
       */
      function open(): State {
        return {
          state: 'open',
          dropdownOpen: true,
          datePickerPopoverOpen: false,
        };
      }

      /**
       * -- Close Dropdown --
       * Close the filter options dropdown list and the date picker popover. The date picker popover
       * is an extended panel from the dropdown list and should never be visible when the dropdown list is closed.
       */
      function close(): State {
        return {
          state: 'closed',
          dropdownOpen: false,
          datePickerPopoverOpen: false,
        };
      }

      /**
       * -- Open Date Picker --
       * Open the date picker popover. This is the second state when the user clicks on the custom date range option.
       */
      function openDatePicker(): State {
        return {
          state: 'datepicker-open',
          dropdownOpen: true,
          datePickerPopoverOpen: true,
        };
      }

      /**
       * -- Close Date Picker --
       * Close the date picker popover.
       */
      function closeDatePicker(): State {
        return {
          state: 'datepicker-closed',
          dropdownOpen: true,
          datePickerPopoverOpen: false,
        };
      }
    },
    {
      state: 'closed',
      dropdownOpen: false,
      datePickerPopoverOpen: false,
    }
  );

  return [state, dispatch] as const;
}

/**
 * DateRangeFilter is a component that renders a DateRangeFilter item.
 * It is used to render a filter that has a date range picker to choose from.
 */
export function DateRangeFilter({
  filter,
  handleSelect,
}: DateRangeableFilterProps) {
  const [query] = useDateRangeQueryParams();
  const toParam = filter.parameters[1];
  const fromParam = filter.parameters[0];
  const to = query[toParam as keyof typeof query];
  const from = query[fromParam as keyof typeof query] ?? null;
  const hasDateRangeApplied = to && from;
  const appliedFiltersCount = hasDateRangeApplied ? 1 : 0;

  const [dateRangeValue, setDateRangeValue] = React.useState({
    start: from ? parseDate(String(from)) : today(getLocalTimeZone()),
    end: to ? parseDate(String(to)) : today(getLocalTimeZone()),
  });

  // Set to and from date values when they are present in the query params and re-render
  // FE-9126: Update the UI with the currently selected date range (https://firstup-io.atlassian.net/browse/FE-9126)
  useEffect(() => {
    if (to && from) {
      setDateRangeValue({
        start: parseDate(String(from)),
        end: parseDate(String(to)),
      });
    }
  }, [to, from]);

  function calendarDateEqualsString(
    date1: CalendarDate,
    date2?: string
  ): boolean {
    return !date2 ? false : date1.compare(parseDate(date2)) === 0;
  }

  function isCustomDateRange(filterOptions: DateOptions[]): boolean {
    return (
      appliedFiltersCount > 0 &&
      !filterOptions.some(
        ({ from, to }) =>
          calendarDateEqualsString(dateRangeValue.start, from) &&
          calendarDateEqualsString(dateRangeValue.end, to)
      )
    );
  }

  const [state, dispatch] = useVisibleDropdowns();

  return (
    <PopoverRoot open={state.datePickerPopoverOpen}>
      <DropdownFilter
        onOpenChange={(open) =>
          dispatch({ type: open ? 'open-dropdown' : 'close-dropdown' })
        }
        open={state.dropdownOpen}
      >
        <div className={styles.FilterBar__filterButtonWrapper}>
          <DropdownFilterTrigger asChild>
            <FilterBarButton
              label={filter.label}
              appliedCount={appliedFiltersCount}
            />
          </DropdownFilterTrigger>
        </div>
        <PopoverAnchor asChild>
          <DropdownFilterContent
            onFocusOutside={(e) => e.preventDefault()}
            onInteractOutside={(e) => {
              e.preventDefault();
              dispatch({ type: 'interact-outside', eventType: e.type });
            }}
          >
            {filter.options.map(({ name, from, to }) => {
              if (!from || !to) {
                // No predefined date range, show the custom date range picker
                return (
                  <PopoverTrigger asChild key={name}>
                    <DropdownFilterItem
                      onSelect={(e) => {
                        e.preventDefault();
                        dispatch({ type: 'toggle-datepicker' });
                      }}
                    >
                      <div
                        className={`${styles.FilterBar__dropdownFilterItemInner}`}
                      >
                        <span>{name}</span>
                        <span>
                          {isCustomDateRange(filter.options) ? (
                            <Icon size={16}>check</Icon>
                          ) : null}
                        </span>
                      </div>
                    </DropdownFilterItem>
                  </PopoverTrigger>
                );
              }
              return (
                <DropdownFilterItem
                  key={name}
                  onSelect={() => {
                    handleSelect(toParam, to);
                    handleSelect(fromParam, from);
                  }}
                >
                  <div
                    className={`${styles.FilterBar__dropdownFilterItemInner}`}
                  >
                    <span>{name}</span>
                    <span>
                      {calendarDateEqualsString(dateRangeValue.start, from) &&
                        calendarDateEqualsString(dateRangeValue.end, to) && (
                          <Icon size={16}>check</Icon>
                        )}
                    </span>
                  </div>
                </DropdownFilterItem>
              );
            })}
          </DropdownFilterContent>
        </PopoverAnchor>
      </DropdownFilter>
      <PopoverContent
        style={{
          width: '336px',
          padding: '16px',
        }}
        className={styles.DateRangeFilter__popoverContent}
        align="start"
        side="bottom"
        avoidCollisions={true}
        onEscapeKeyDown={() => dispatch({ type: 'toggle-datepicker' })}
      >
        <DateRangePicker
          label="Date Range"
          value={hasDateRangeApplied ? dateRangeValue : undefined}
          onChange={(value) => {
            handleSelect(fromParam, value.start.toString());
            handleSelect(toParam, value.end.toString());
          }}
        />
      </PopoverContent>
    </PopoverRoot>
  );
}
