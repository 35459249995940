import api from '../config/api';
import eva from '../config/eva';

class ContentService {
  constructor(id, trackingId) {
    this.id = id;
    this.trackingId = trackingId;
  }

  fetch = (params) => {
    return api.get(`/feed/${this.id}/show_contents`, {
      params: {
        ...params,
        width: 768,
      },
    });
  };

  fetchApproval = (params) => {
    return api.get(`/contents/${this.id}`, {
      baseURL: this.v3baseUrl,
      params: {
        ...params,
        width: 768,
      },
    });
  };

  fetchByFeedItemId = (feedItemId, params) => {
    return api.get(`/feed/${feedItemId}`, {
      params: {
        ...params,
        width: 768,
      },
    });
  };

  fetchLikes = () => {
    return api.get(`/contents/${this.id}/reactions/like`, {
      params: {
        page_size: 9999,
      },
    });
  };

  fetchStats = () => {
    return api.get(`/feed/${this.id}/stats`);
  };

  fetchShareableUrl = () => {
    return api.post(`/contents/${this.id}/trackable_links`);
  };

  like = () => {
    return api.post(`/contents/${this.id}/reactions/like`, {
      tracking_id: this.trackingId,
    });
  };

  unlike = () => {
    return api.delete(`/contents/${this.id}/reactions/like`, {
      tracking_id: this.trackingId,
    });
  };

  bookmark = () => {
    return api.post(`/contents/${this.id}/reactions/bookmark`, {
      tracking_id: this.trackingId,
    });
  };

  unbookmark = () => {
    return api.delete(`/contents/${this.id}/reactions/bookmark`, {
      tracking_id: this.trackingId,
    });
  };

  share = ({ url, network, channelId }) => {
    return api.post(`/contents/${this.id}/engagements`, {
      engagements: [
        {
          content_channel_id: channelId,
          share_intents_attributes: [
            {
              trackable_url: url,
              channel: network,
            },
          ],
        },
      ],
      content_id: this.id,
      tracking_id: this.trackingId,
    });
  };

  view = () => {
    return api.post(`/contents/${this.id}/reactions/view`, {
      tracking_id: this.trackingId,
    });
  };

  flagContent = () => {
    return api.post(`/contents/${this.id}/flag`, {
      tracking_id: this.trackingId,
    });
  };

  acknowledge = () => {
    return api.post(`/feed/${this.id}/acknowledge`, {
      tracking_id: this.trackingId,
    });
  };

  //program id as an argument to this function is a wart from pulling this in from advo. needed for security, ideally this would be pulled from global state
  fetchRelated = ({ program_id }) => {
    const url = `/feed/${this.id}/related` + `?program_id=${program_id}`;
    return api.get(url).then((json) => json.data);
  };

  fetchMoreLikeThis = ({ program_id }) => {
    const url = `/feed/${this.id}/more_like_this` + `?program_id=${program_id}`;
    return api.get(url).then((json) => json.data);
  };

  get v3baseUrl() {
    return api.defaults.baseURL.replace('v2', 'v3');
  }

  approveContent = () => {
    return eva.post(`/content_submissions/${this.id}/approve`, {
      tracking_id: this.trackingId,
    });
  };
  rejectContent = () => {
    return eva.post(`/content_submissions/${this.id}/reject`, {
      tracking_id: this.trackingId,
    });
  };
}

export default ContentService;
