import cx from 'classnames';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatronSelector } from '../../../common/use-patron-selector';
import useScreenSize from '../../../common/use-screen-size';
import { CommentAssistantSuggestionSelection } from '../../../models/comments/types';
import { Feature, getFeatureFlag } from '../../../models/features/features';
import { Button, Icon, SubmitCommentIcon } from '../../ui';
import CommentAssistantButton from './comment-assistant-button';
import { CommentAssistantDropdownMenu } from './comment-assistant-menus/dropdown-menu';
import CommentAttachmentButton from './comment-attachment-button';
import { useDispatch } from 'react-redux';

interface CommentFormFooterProps {
  commentLength: number;
  commentLengthMax: number;
  disabled: boolean;
  suggestionDisabled: boolean;
  onSubmit: () => void;
  onEmojiIconClick: () => void;
  onMentionIconClick: () => void;
  onAttachmentClick: (file: File) => void;
  onSuggestionSelected: (
    suggestion: CommentAssistantSuggestionSelection
  ) => void;
  // temporary solution until we have converted the `CommentForm` component to a functional component
  setIsAssistantMobileMenuOpen?: Dispatch<SetStateAction<boolean>>;
}

const CommentFormFooter: React.FC<CommentFormFooterProps> = ({
  commentLength,
  commentLengthMax,
  disabled,
  suggestionDisabled,
  onSubmit,
  onEmojiIconClick,
  onMentionIconClick,
  onAttachmentClick,
  onSuggestionSelected,
  setIsAssistantMobileMenuOpen,
}) => {
  const { t } = useTranslation();
  const commentAssistantEnabled = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.COMMENT_ASSISTANCE_ENABLED)
  );
  const consensusMigration = usePatronSelector((state) =>
    getFeatureFlag(state, Feature.DISCOURSE_CONSENSUS_MIGRATION)
  );
  const commentAttachmentsEnabled =
    usePatronSelector((state) =>
      getFeatureFlag(state, Feature.COMMENT_ATTACHMENTS_ENABLED)
    ) &&
    (consensusMigration == 'ReadConsensusWriteBoth' ||
      consensusMigration == 'OnlyConsensus');
  const { isTabletOrSmaller } = useScreenSize();

  const toggleAssistantMobileMenu = () => {
    setIsAssistantMobileMenuOpen?.((prevOpen) => !prevOpen);
  };

  const handleSuggestionSelected = (
    selection: CommentAssistantSuggestionSelection
  ) => {
    onSuggestionSelected(selection);
  };

  const counterClassName = cx('counter', {
    'counter--error': commentLength > commentLengthMax,
  });

  return (
    <>
      <div className="comment-form-footer">
        <div className="actions">
          <div className="basic-actions">
            <Button
              type="button"
              title={t('comments.actions.emoji')}
              onClick={onEmojiIconClick}
              aria-label={t('comments.actions.emoji')}
            >
              <Icon type={'insert_emoticon'} />
            </Button>

            <Button
              type="button"
              title={t('comments.actions.mention')}
              onClick={onMentionIconClick}
              aria-label={t('comments.actions.mention')}
            >
              <Icon type={'alternate_email'} />
            </Button>
            {commentAttachmentsEnabled ? (
              <CommentAttachmentButton onFileSelect={onAttachmentClick} />
            ) : null}
          </div>
          {commentAssistantEnabled ? (
            !isTabletOrSmaller ? (
              <CommentAssistantDropdownMenu
                disabled={suggestionDisabled}
                onSuggestionSelected={handleSuggestionSelected}
              />
            ) : (
              <CommentAssistantButton
                disabled={suggestionDisabled}
                onClick={toggleAssistantMobileMenu}
              />
            )
          ) : null}
        </div>

        <div className="submit">
          <span
            role="status"
            aria-label="Character count"
            className={counterClassName}
          >
            {commentLength}/{commentLengthMax}
          </span>
          <Button
            type="submit"
            disabled={disabled}
            onClick={onSubmit}
            aria-label={t('common.submit')}
          >
            <SubmitCommentIcon />
          </Button>
        </div>
      </div>
    </>
  );
};

export default CommentFormFooter;
