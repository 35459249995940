import { useCallback, useContext, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  CheckboxLabel,
  Icon,
} from '@socialchorus/shared-ui-components';
import { NotificationFilterTags } from '../../../models/notifications/types';
import { mapFilterTagToIcon } from '../toolbar/filter-dropdown';
import { NotificationContext } from '../context';
import styles from './../notification-center.module.scss';

export type FilterMobileOverlayProps = {
  allowedOptions: NotificationFilterTags[];
};

export const FilterMobileOverlay = ({
  allowedOptions,
}: FilterMobileOverlayProps) => {
  const { t } = useTranslation();

  const {
    mobileOverlay: { close },
    filterTags: [filterTags, setFilterTags],
  } = useContext(NotificationContext);

  const [newFilterTags, setNewFilterTags] =
    useState<NotificationFilterTags[]>(filterTags);

  const hasChanges = filterTags !== newFilterTags;

  const handleOptionClick = useCallback(
    (tag: NotificationFilterTags) => {
      if (newFilterTags.includes(tag)) {
        setNewFilterTags(newFilterTags.filter((t) => t !== tag));
      } else {
        setNewFilterTags([...newFilterTags, tag]);
      }
    },
    [newFilterTags, setNewFilterTags]
  );

  const handleApplyClick = useCallback(() => {
    setFilterTags(newFilterTags);
    close();
  }, [newFilterTags, setFilterTags, close]);

  return (
    <div className={styles.filterMobileScreenContainer}>
      <div className={styles.buttonMenu}>
        {!hasChanges ? (
          <Button
            variant="text"
            label={t('common.back')}
            leftIcon={<Icon>arrow_back</Icon>}
            onClick={() => close()}
          />
        ) : (
          <>
            <Button
              variant="text"
              label={t('common.cancel')}
              leftIcon={<Icon>close</Icon>}
              onClick={() => close()}
            />
            <Button
              variant="text"
              label={t('common.apply')}
              leftIcon={<Icon>check</Icon>}
              onClick={handleApplyClick}
            />
          </>
        )}
      </div>
      <div
        className={cx(
          styles.menuTitle,
          'text-body-1 text-black-90 font-semibold'
        )}
      >
        {t('notification_center.filter_by')}
      </div>
      {allowedOptions.map((tag) => (
        <div key={tag} className={styles.item}>
          <Checkbox
            id={`filter-overlay-${tag}`}
            checked={newFilterTags.includes(tag)}
            onCheckedChange={() => handleOptionClick(tag)}
          >
            <CheckboxLabel
              className={styles.itemLabel}
              htmlFor={`filter-overlay-${tag}`}
            >
              {mapFilterTagToIcon({ tag, colored: true })}
              <span className="text-body-1 text-black-90">
                {t(`notification_center.tags.${tag}`)}
              </span>
            </CheckboxLabel>
          </Checkbox>
        </div>
      ))}
    </div>
  );
};
