import { useEffect } from 'react';
import MessageService from '../services/message';
import { useTrackingId } from './use-tracking-id';

const useMessageViewer = (messageId: string) => {
  const trackingId = useTrackingId();

  useEffect(() => {
    if (messageId) {
      new MessageService(messageId, trackingId).view();
    }
  }, [messageId]);
};

export default useMessageViewer;
