import { Alert, Button } from '@socialchorus/shared-ui-components';
import styles from '../notification-center.module.scss';
import { useCallback, useContext, useEffect } from 'react';
import { NotificationContext } from '../context';
import {
  NotificationCenterTabs,
  NotificationFilterTags,
} from '../../../models/notifications/types';
import { useRecoilValue } from 'recoil';
import { notificationCountsState } from '../../../models/notifications/recoil-state';
import { useTranslation } from 'react-i18next';

type TasksAlertProps = {
  onViewAllTasksClick: () => void;
};

export const TasksAlert = ({ onViewAllTasksClick }: TasksAlertProps) => {
  const { t } = useTranslation();
  const {
    filterTags: [filterTags, setFilterTags],
    tabs: {
      activeTab: [activeTab, setActiveTab],
    },
    multiSelect: {
      enabled: [multiSelectEnabled],
    },
    search: {
      query: [searchQuery, setSearchQuery],
    },
    tasksAlertFilterActive: [tasksAlertFilterActive, setTasksAlertFilterActive],
  } = useContext(NotificationContext);
  const counts = useRecoilValue(notificationCountsState);

  const taskCount =
    (counts[NotificationCenterTabs.MESSAGES]?.action_required || 0) +
    (counts[NotificationCenterTabs.MESSAGES]?.important || 0);

  const showAlertPrompt =
    taskCount > 0 &&
    filterTags.length === 0 &&
    !multiSelectEnabled &&
    !searchQuery &&
    [NotificationCenterTabs.MESSAGES, NotificationCenterTabs.ACTIVITY].includes(
      activeTab
    );

  const handleClick = useCallback(() => {
    setFilterTags([
      NotificationFilterTags.IMPORTANT,
      NotificationFilterTags.ACTION_REQUIRED,
    ]);
    setActiveTab(NotificationCenterTabs.MESSAGES);
    setSearchQuery(undefined);
    onViewAllTasksClick();
    setTasksAlertFilterActive(true);
  }, [
    onViewAllTasksClick,
    setTasksAlertFilterActive,
    setFilterTags,
    setActiveTab,
    setSearchQuery,
  ]);

  useEffect(() => {
    const filterConditionsMet =
      filterTags.includes(NotificationFilterTags.ACTION_REQUIRED) &&
      filterTags.includes(NotificationFilterTags.IMPORTANT);

    if (
      !filterConditionsMet ||
      activeTab !== NotificationCenterTabs.MESSAGES ||
      multiSelectEnabled
    ) {
      setTasksAlertFilterActive(false);
    } else if (filterConditionsMet) {
      setTasksAlertFilterActive(true);
    }
  }, [filterTags, activeTab, multiSelectEnabled, setTasksAlertFilterActive]);

  return tasksAlertFilterActive ? (
    <div className={styles.tasksContainer}>
      <Alert iconName="check_circle" size="compact" type="info">
        {
          <p
            className="text-body-bold text-black-90"
            dangerouslySetInnerHTML={{
              __html: t('notification_center.tasks.filtering', {
                count: taskCount,
              }),
            }}
          />
        }
      </Alert>
    </div>
  ) : showAlertPrompt ? (
    <div className={styles.tasksContainer}>
      <Alert
        size="compact"
        type="info"
        iconName="default"
        actionButton={
          <Button
            variant="text"
            size="compact"
            label={t('notification_center.tasks.view')}
            onClick={handleClick}
          />
        }
      >
        {
          <p
            className="text-body-bold text-black-90"
            dangerouslySetInnerHTML={{
              __html: t('notification_center.tasks.prompt', {
                count: taskCount,
              }),
            }}
          />
        }
      </Alert>
    </div>
  ) : null;
};
