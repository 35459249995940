/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Notification,
  NotificationCenterTabs,
  NotificationFilterTags,
} from '../../models/notifications/types';
import { ArchivePartialConfirmationMobileOverlayProps } from './mobileOverlays/archive-partial-confirmation';
import { FilterMobileOverlayProps } from './mobileOverlays/filter';
import { ToolbarExtraOptionsMobileOverlayProps } from './mobileOverlays/toolbar-extra-options';

export enum MobileOverlayKeys {
  ARCHIVE_PARTIAL_CONFIRMATION = 'archivePartialConfirmation',
  FILTER = 'filter',
  TOOLBAR_EXTRA_OPTIONS = 'toolbarExtraOptions',
}

//when in a mobile form factor, some dropdowns/modals are replayed with a full body overlay replacing the notification center body.
//this will be used in the global context to manage which one is active, if any.
export type MobileOverlays = {
  [MobileOverlayKeys.ARCHIVE_PARTIAL_CONFIRMATION]: ArchivePartialConfirmationMobileOverlayProps;
  [MobileOverlayKeys.FILTER]: FilterMobileOverlayProps;
  [MobileOverlayKeys.TOOLBAR_EXTRA_OPTIONS]: ToolbarExtraOptionsMobileOverlayProps;
};

type NotificationContextType = {
  tabs: {
    currentTabs: [
      NotificationCenterTabs[],
      Dispatch<SetStateAction<NotificationCenterTabs[]>>
    ];
    activeTab: [
      NotificationCenterTabs,
      Dispatch<SetStateAction<NotificationCenterTabs>>
    ];
    tabTotals: [
      { [key in NotificationCenterTabs]?: number },
      Dispatch<SetStateAction<{ [key in NotificationCenterTabs]?: number }>>
    ];
  };
  multiSelect: {
    enabled: [boolean, Dispatch<SetStateAction<boolean>>];
    selectedItems: [Notification[], Dispatch<SetStateAction<Notification[]>>];
    selectAll: [
      boolean | 'indeterminate',
      Dispatch<SetStateAction<boolean | 'indeterminate'>>
    ];
  };
  mobileOverlay: {
    activeId: keyof MobileOverlays | null;
    props: MobileOverlays[keyof MobileOverlays];
    open: <T extends keyof MobileOverlays>({
      screen,
      props,
    }: {
      screen: T;
      props: MobileOverlays[T];
    }) => void;
    close: () => void;
  };
  filterTags: [
    NotificationFilterTags[],
    Dispatch<SetStateAction<NotificationFilterTags[]>>
  ];
  mutationIsLoading: [boolean, Dispatch<SetStateAction<boolean>>]; //used when doing (and to differentiate) a non-optimistic update of the feed
  tasksAlertFilterActive: [boolean, Dispatch<SetStateAction<boolean>>];
  search: {
    query: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
    tabResultCounts: [
      {
        [key in NotificationCenterTabs]?: number;
      },
      Dispatch<SetStateAction<{ [key in NotificationCenterTabs]?: number }>>
    ];
  };
};

export const NotificationContext = createContext<NotificationContextType>({
  tabs: {
    currentTabs: [[], () => {}],
    activeTab: [NotificationCenterTabs.MESSAGES, () => {}],
    tabTotals: [{}, () => {}],
  },
  multiSelect: {
    enabled: [false, () => {}],
    selectedItems: [[], () => {}],
    selectAll: [false, () => {}],
  },
  mobileOverlay: {
    activeId: null,
    props: {} as MobileOverlays[keyof MobileOverlays],
    open: () => {},
    close: () => {},
  },
  filterTags: [[], () => {}],
  mutationIsLoading: [false, () => {}],
  tasksAlertFilterActive: [false, () => {}],
  search: {
    query: [undefined, () => {}],
    tabResultCounts: [{}, () => {}],
  },
});

export const NotificationContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [tabs, setTabs] = useState<NotificationCenterTabs[]>([
    NotificationCenterTabs.MESSAGES,
    NotificationCenterTabs.ACTIVITY,
  ]);
  const [activeTab, setActiveTab] = useState<NotificationCenterTabs>(
    NotificationCenterTabs.MESSAGES
  );
  const [tabTotals, setTabTotals] = useState<{
    [key in NotificationCenterTabs]?: number;
  }>({});
  const [filterTags, setFilterTags] = useState<NotificationFilterTags[]>([]);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState(false);
  const [multiSelectedItems, setMultiSelectedItems] = useState<Notification[]>(
    []
  );
  const [selectAll, setSelectAll] = useState<boolean | 'indeterminate'>(false);
  const [mutationIsLoading, setMutationIsLoading] = useState(false); //used for non-optimistic updates
  const [tasksAlertFilterActive, setTasksAlertFilterActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [tabResultSearchCounts, setTabResultSearchCounts] = useState<{
    [key in NotificationCenterTabs]?: number;
  }>({});

  const [activeMobileOverlayId, setActiveMobileOverlayId] = useState<
    keyof MobileOverlays | null
  >(null);
  const [mobileScreen, setMobileScreen] = useState<
    MobileOverlays[keyof MobileOverlays]
  >({} as MobileOverlays[keyof MobileOverlays]);

  const openMobileOverlay = <T extends keyof MobileOverlays>({
    screen,
    props,
  }: {
    screen: T;
    props: MobileOverlays[T];
  }) => {
    setActiveMobileOverlayId(screen);
    setMobileScreen(props);
  };

  const closeMobileOverlay = () => {
    setActiveMobileOverlayId(null);
    setMobileScreen({} as MobileOverlays[keyof MobileOverlays]);
  };

  //if filters change at all, reset multi select
  useEffect(() => {
    setMultiSelectedItems([]);
    setSelectAll(false);
  }, [filterTags]);

  return (
    <NotificationContext.Provider
      value={{
        tabs: {
          currentTabs: [tabs, setTabs],
          activeTab: [activeTab, setActiveTab],
          tabTotals: [tabTotals, setTabTotals],
        },
        multiSelect: {
          enabled: [multiSelectEnabled, setMultiSelectEnabled],
          selectedItems: [multiSelectedItems, setMultiSelectedItems],
          selectAll: [selectAll, setSelectAll],
        },
        search: {
          query: [searchQuery, setSearchQuery],
          tabResultCounts: [tabResultSearchCounts, setTabResultSearchCounts],
        },
        mobileOverlay: {
          activeId: activeMobileOverlayId,
          props: mobileScreen,
          open: openMobileOverlay,
          close: closeMobileOverlay,
        },
        filterTags: [filterTags, setFilterTags],
        mutationIsLoading: [mutationIsLoading, setMutationIsLoading],
        tasksAlertFilterActive: [
          tasksAlertFilterActive,
          setTasksAlertFilterActive,
        ],
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
