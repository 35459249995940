import { Button, Icon, Toast } from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { usePatronSelector } from '../../../common/use-patron-selector';
import { contentSelectors } from '../../../models/content';
import { useMutation } from '@tanstack/react-query';
import ContentService from '../../../services/content';
import { useDispatch } from 'react-redux';
import contentActions from '../../../models/content/actions';
import useFeatureFlag from '../../../common/use-feature-flag';
import { Feature } from '../../../models/features/features';

interface ApprovalFooterProps {
  contentId: number;
}

export const ApprovalFooter = ({ contentId }: ApprovalFooterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const enabled = useFeatureFlag(Feature.NOTIFICATION_CENTER_ENABLED);

  const approvalStatus = usePatronSelector((state) =>
    contentSelectors.getContentApprovalStatus(state, { contentId })
  );

  const {
    mutate: approve,
    isError: approveIsError,
    isLoading: approveLoading,
  } = useMutation({
    mutationFn: async () => {
      return new ContentService(contentId).approveContent();
    },
    onSuccess: () => {
      dispatch(
        contentActions.updateContent(contentId, { approval_state: 'approved' })
      );
    },
  });

  const {
    mutate: reject,
    isError: rejectIsError,
    isLoading: rejectLoading,
  } = useMutation({
    mutationFn: async () => {
      return new ContentService(contentId).rejectContent();
    },
    onSuccess: () => {
      dispatch(
        contentActions.updateContent(contentId, { approval_state: 'rejected' })
      );
    },
  });

  if (!enabled || !approvalStatus) {
    return null;
  }

  const isApproved = approvalStatus === 'approved';
  const isRejected = approvalStatus === 'rejected';
  const isPending = approvalStatus === 'pending';

  return (
    <div className="approval-footer">
      <div className="approval-bar">
        <div className="approval-bar__message">
          {isPending && (
            <>
              <Icon>lightbulb</Icon>
              {t('content.approval.approve_prompt')}
            </>
          )}
          {isApproved && (
            <>
              <Icon>check</Icon>
              {t('content.approval.approved')}
            </>
          )}
          {isRejected && (
            <>
              <Icon>warning</Icon>
              {t('content.approval.denied')}
            </>
          )}
        </div>
        {isPending && (
          <div className="approval-bar__actions">
            <Button
              label={t('content.approval.deny')}
              variant="primary"
              leftIcon={<Icon>close</Icon>}
              isLoading={rejectLoading}
              disabled={approveLoading}
              onClick={() => reject()}
            />
            <Button
              label={t('content.approval.approve')}
              variant="primary"
              leftIcon={<Icon>check</Icon>}
              isLoading={approveLoading}
              disabled={rejectLoading}
              onClick={() => approve()}
            />
          </div>
        )}
      </div>

      {(approveIsError || rejectIsError) && (
        <Toast
          title={t('content.approval.error')}
          variant="error"
          defaultOpen
        />
      )}
    </div>
  );
};
