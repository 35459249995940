import { AxiosPromise, AxiosResponse } from 'axios';
import {
  NotificationFilterTags,
  NotificationCenterTabs,
  NotificationCounts,
  FeedPageData,
  NotificationFeedTypes,
  BulkEditAction,
  SearchFeedPageData,
} from '../models/notifications/types';
import MojoApiFactory from '../config/api-factory';

export const getNotificationCounts = async () => {
  return (await MojoApiFactory.notifications.get(
    'notifications/counts'
  )) as AxiosResponse<NotificationCounts>;
};

const mapTabToFeedType = (tab: NotificationCenterTabs) => {
  return {
    [NotificationCenterTabs.MESSAGES]: NotificationFeedTypes.MESSAGES,
    [NotificationCenterTabs.ACTIVITY]: NotificationFeedTypes.ACTIVITY,
    [NotificationCenterTabs.ARCHIVE]: NotificationFeedTypes.ARCHIVED,
  }[tab];
};

const PAGE_SIZE = 10;

export const getNotificationFeed = async (
  activeTab: NotificationCenterTabs,
  filterTags: NotificationFilterTags[],
  pageParam: number
) => {
  const response = await MojoApiFactory.notifications.get(
    `notifications/?${mapTabToFeedType(activeTab)}=true${filterTags
      .map((tag) => `&${tag}=true`)
      .join('')}&page=${pageParam}&page_size=${PAGE_SIZE}`
  );
  return {
    data: response.data?.data.map(
      (datum: { id: string; attributes: Omit<Notification, 'id'> }) => {
        return {
          id: datum.id,
          ...datum.attributes,
        };
      }
    ),
    meta: response.data?.meta,
  } as FeedPageData;
};

export const getNotificationSearchFeed = async (
  activeTab: NotificationCenterTabs,
  filterTags: NotificationFilterTags[],
  searchQuery: string,
  pageParam: number
) => {
  const response = await MojoApiFactory.notifications.get(
    `notifications/search?${mapTabToFeedType(activeTab)}=true${filterTags
      .map((tag) => `&${tag}=true`)
      .join('')}&query=${searchQuery}&page=${pageParam}&page_size=${PAGE_SIZE}`
  );
  return {
    data: response.data?.data.map(
      (datum: { id: string; attributes: Omit<Notification, 'id'> }) => {
        return {
          id: datum.id,
          ...datum.attributes,
        };
      }
    ),
    meta: response.data?.meta,
  } as SearchFeedPageData;
};

export type BulkEditResponse = {
  modified: {
    count: number;
    ids: number[];
  };
  unmodified: {
    count: number;
    ids: number[];
  };
  counts: NotificationCounts;
  undo_action: string;
  errors: string[];
};

export type BulkEditNotificationsParams = {
  ids: string[];
  action: BulkEditAction;
};

//todo: consider whether to return an axios promise or just the data / error
export const bulkEditNotifications = async ({
  ids,
  action,
}: BulkEditNotificationsParams) => {
  return MojoApiFactory.notifications.put(`notifications/bulk_edit`, {
    ids,
    edit_action: action,
  }) as AxiosPromise<BulkEditResponse>;
};
