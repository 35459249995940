import { Button, Icon } from '../ui'; // I know this should be from @socialchorus/shared-ui-components but using these to re-use more of the old implimentation
import { Message } from '../../models/messages/types';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  messageById,
  messageCompletedState,
} from '../../models/messages/recoil-state';
import MessageService from '../../services/message';
import { useState } from 'react';
import { useTrackingId } from '../../common/use-tracking-id';

type AcknowledgementFooterProps = {
  messageId: Message['id'];
};

export const AcknowledgementFooter = ({
  messageId,
}: AcknowledgementFooterProps) => {
  const trackingId = useTrackingId();
  const { t } = useTranslation();
  const message = useRecoilValue(messageById(messageId));
  const prereqsCompleted = useRecoilValue(messageCompletedState(messageId));

  const [isAcknowledged, setIsAcknowledged] = useState(
    !!message?.acknowledgement?.acknowledged_at
  );

  const ackButtonText = isAcknowledged
    ? message?.acknowledgement?.acked_label
    : message?.acknowledgement?.label;
  const handleClick = async () => {
    new MessageService(messageId, trackingId)
      .acknowledge()
      .then(() => {
        setIsAcknowledged(true);
      })
      .catch((err) => {
        // TODO: error toast?
      });
  };

  return (
    <div className="info-footer">
      <div className="acknowledgement-footer">
        <div className="acknowledgement-bar">
          <div className="acknowledgement-bar__message">
            <Icon type="emoji_objects" size="3xl" />
            <div>{t('content.acknowledgement')}</div>
          </div>
          <div className="acknowledgement-bar__action">
            <Button
              theme="primary"
              icon={isAcknowledged ? 'done' : undefined}
              onClick={handleClick}
              disabled={!prereqsCompleted || isAcknowledged}
              aria-label={ackButtonText}
            >
              {ackButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
