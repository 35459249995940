import { AxiosRequestConfig, AxiosResponse } from 'axios';

import eva from '../config/eva';
import store from '../config/store';
import { ApiRequest } from '../models/assistant/interfaces/ApiRequest';
import { AssistantResponse } from '../models/assistant/interfaces/AssistantResponse';
import {
  SearchProvider,
  SearchResponse,
} from '../models/assistant/interfaces/SearchResponse';
import { Inbox } from '../models/assistant/interfaces/subject/Inbox';
import { Service } from '../models/assistant/interfaces/subject/Service';
import { CommandButton } from '../models/assistant/interfaces/Command';
import { Card } from '../models/assistant/interfaces/subject/Summary';
import { Pageable } from '../models/assistant/interfaces/Pageable';
import { SearchHistory } from '../models/assistant/interfaces/SearchHistory';

type RequestParams = AxiosRequestConfig['params'];

const transformResponse = <T>(res: AxiosResponse<{ data: T }>): T => {
  return res.data.data;
};

class AssistantService {
  private readonly programId: string;
  private readonly userId: string;

  constructor(
    programId: string | undefined = undefined,
    advocateId: number | undefined = undefined
  ) {
    const state = store.getState();
    this.programId = programId || state.program.id;
    this.userId = advocateId || state.advocate.id;
  }

  fetchCommands = (params?: RequestParams) => {
    return eva
      .get<{ data: CommandButton[] }>(`/assistant/commands`, {
        params,
      })
      .then(transformResponse);
  };

  fetchBootstrap = (params?: RequestParams) => {
    return eva
      .get(`/assistant/bootstrap`, {
        params,
      })
      .then(transformResponse);
  };

  fetchInbox = (params?: RequestParams) => {
    return eva
      .get<{ data: AssistantResponse<Inbox>[] }>(`/assistant/inbox`, {
        params,
      })
      .then(transformResponse);
  };

  fetchInboxCount = (params?: RequestParams) => {
    return eva.get<{ count: number }>(`/assistant/inbox/count`, {
      params,
    });
  };

  fetchQuickActions = (params?: RequestParams) => {
    return eva
      .get<{ data: AssistantResponse<Service>[] }>(`/assistant/quick_actions`, {
        params,
      })
      .then(transformResponse);
  };

  fetchNotifications = (params?: RequestParams) => {
    return eva.get(`/assistant/notifications`, {
      params,
    });
  };

  fetchAnsweredPolls = (params?: RequestParams) => {
    return eva.get<{ data: AssistantResponse<Card>[]; meta: Pageable }>(
      `/assistant/polls/answered`,
      {
        params,
      }
    );
  };

  fetchTodos = (params?: RequestParams) => {
    return eva.get(`/assistant/todos`, {
      params,
    });
  };

  fetchTodo = (todoId?: string, params?: RequestParams) => {
    return eva.get(`/assistant/todos/${todoId}`, {
      params,
    });
  };

  fetchSearchProviders = () => {
    return eva
      .get<{ data: SearchProvider[] }>(`/assistant/search_providers`)
      .then(transformResponse);
  };

  fetchIntegrations = (params?: RequestParams) => {
    return eva
      .get<{ data: AssistantResponse<Service> }>(`/assistant/integrations`, {
        params,
      })
      .then(transformResponse);
  };

  fetchIntegration = (integrationId: string, params?: RequestParams) => {
    return eva
      .get(`/assistant/integrations/${integrationId}`, {
        params,
      })
      .then(transformResponse);
  };

  fetchCommandDetail = (
    integrationId: string,
    commandName: string,
    params?: RequestParams
  ) => {
    return eva
      .get<{ data: CommandButton }>(
        `/assistant/integrations/${integrationId}/command/${commandName}`,
        {
          params,
        }
      )
      .then(transformResponse);
  };

  fetchSummaryDetail = (url: string) => {
    const reqUrl = url.split('/api/v1')[1];
    return eva.get(reqUrl);
  };

  search = (
    params?: RequestParams
  ): Promise<SearchResponse | { data: AssistantResponse[] }> => {
    return eva
      .post(`/assistant/search`, {
        ...params,
      })
      .then((res) => res.data);
  };

  fetchUserPreviousSearches = (params?: RequestParams) => {
    return eva
      .get<{ data: SearchHistory }>(`/assistant/search_history`, {
        params,
      })
      .then(transformResponse);
  };

  handleRequest = (request: ApiRequest, d?: Record<string, unknown>) => {
    const data = {
      ...d,
      ...(request.payload_string ? JSON.parse(request.payload_string) : null),
    };
    return eva.request({
      url: request.endpoint,
      method: request.method,
      responseType: request.responseType || 'json',
      baseURL: this.unscopedBaseUrl,
      data,
    });
  };

  get unscopedBaseUrl() {
    return eva.defaults.baseURL?.replace(/\/api\/v1\/programs\/\d+/, '/');
  }
}

export default AssistantService;
