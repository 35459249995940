import React from 'react';
import { Container } from '@socialchorus/shared-ui-components';

import styles from './assistant.module.scss';
import { DEFAULT_SKIP_TO_CONTENT_ID } from '../../common/use-skip-to-content';
import { Trans } from 'react-i18next';
import { AssistantAvatar } from './assistant-avatar';
import { useProgram } from '../../common/use-program';

interface AssistantWelcomeMessageProps {
  statusRef: React.RefObject<HTMLDivElement>;
}

export const AssistantWelcomeMessage: React.FC<
  AssistantWelcomeMessageProps
> = ({ statusRef }) => {
  const { name } = useProgram();

  return (
    <div className={styles.welcomeDialog}>
      <div className={styles.assistantMessage}>
        <div className={styles.author}>
          <AssistantAvatar />
        </div>
        <div className={styles.messages}>
          <Container
            fullWidth
            id={DEFAULT_SKIP_TO_CONTENT_ID}
            ref={statusRef}
            shadow="light"
            shape="medium"
            variant="border"
            className={styles.welcomeMessage}
          >
            <Trans
              i18nKey="assistant.welcome.greeting"
              components={{ strong: <strong />, br: <br /> }}
              values={{ name }}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};
