import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { notificationCountsState } from '../../../models/notifications/recoil-state';
import { NotificationContext } from '../context';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getNotificationFeed,
  getNotificationSearchFeed,
} from '../../../services/notifications';
import {
  NotificationCenterTabs,
  NotificationFilterTags,
  FeedPageData,
  SearchFeedPageData,
} from '../../../models/notifications/types';

export const getNotificationFeedQueryKey = ({
  activeTab,
  filterTags,
  searchQuery,
}: {
  activeTab: NotificationCenterTabs;
  filterTags: NotificationFilterTags[];
  searchQuery?: string;
}) => [
  'notifications',
  activeTab,
  filterTags.reduce((acc, tag) => {
    acc[tag] = true;
    return acc;
  }, {} as { [key in NotificationFilterTags]: boolean }),
  searchQuery,
];

export const useNotificationFeed = () => {
  const setNotificationCounts = useSetRecoilState(notificationCountsState);
  const {
    tabs: {
      activeTab: [activeTab],
      tabTotals: [, setTabTotals],
    },
    multiSelect: {
      selectedItems: [, setMultiSelectedItems],
      selectAll: [, setSelectAll],
    },
    filterTags: [filterTags],
    mutationIsLoading: [mutationIsLoading],
    search: {
      query: [searchQuery],
      tabResultCounts: [, setTabResultSearchCounts],
    },
  } = useContext(NotificationContext);

  //used to store the value of mutationIsLoading from previous render loops
  const wasMutationLoading = useRef(false);
  const [showLoading, setShowLoading] = useState(false);

  const alignMultiSelectItems = useCallback(
    <T extends FeedPageData | SearchFeedPageData>(page: T): T => {
      //for each item in the multi-select array, if an id matches with the data in the page, use the new object from the page
      setMultiSelectedItems((prev) =>
        prev.map((item) => {
          const newItem = page.data.find((newItem) => newItem.id === item.id);
          return newItem || item;
        })
      );
      return page;
    },
    [setMultiSelectedItems]
  );

  const handleSearchCounts = useCallback(
    (response: SearchFeedPageData) => {
      if (response?.meta?.counts) {
        setTabResultSearchCounts({
          [NotificationCenterTabs.ACTIVITY]:
            response.meta.counts[NotificationCenterTabs.ACTIVITY]?.count,
          [NotificationCenterTabs.MESSAGES]:
            response.meta.counts[NotificationCenterTabs.MESSAGES]?.count,
        });
      }
      return response;
    },
    [setTabResultSearchCounts]
  );

  const handleFeedCounts = useCallback(
    (response: FeedPageData) => {
      const total = response?.meta?.totalResults;
      if (total) {
        setTabTotals((prev) => ({ ...prev, [activeTab]: total }));
      }
      if (response?.meta?.counts) {
        setNotificationCounts(response.meta.counts);
      }
      return response;
    },
    [setNotificationCounts, setTabTotals, activeTab]
  );

  const {
    data,
    fetchNextPage: reactQueryFetchNextPage,
    isLoading,
    isRefetching,
    hasNextPage,
    isFetchingNextPage,
    error,
    isError,
  } = useInfiniteQuery(
    getNotificationFeedQueryKey({ activeTab, filterTags, searchQuery }),
    async ({ pageParam = 1 }) => {
      return searchQuery
        ? getNotificationSearchFeed(
            activeTab,
            filterTags,
            searchQuery,
            pageParam
          )
            .then(alignMultiSelectItems)
            .then(handleSearchCounts)
        : getNotificationFeed(activeTab, filterTags, pageParam)
            .then(alignMultiSelectItems)
            .then(handleFeedCounts);
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.length ? lastPage.meta.page + 1 : undefined,
    }
  );

  const fetchNextPage = useCallback(() => {
    setSelectAll((prev) => (prev === true ? 'indeterminate' : prev));
    reactQueryFetchNextPage();
  }, [reactQueryFetchNextPage, setSelectAll]);

  //goal of these 2 useEffects is to show the loading spinner when doing a non-optimistic mutation, but to not show it when doing an optimistic mutation
  useEffect(() => {
    if (mutationIsLoading) {
      wasMutationLoading.current = true;
    }
  }, [mutationIsLoading, isRefetching]);

  useEffect(() => {
    if (isLoading || mutationIsLoading) {
      setShowLoading(true);
    } else if (isRefetching && !!wasMutationLoading.current) {
      setShowLoading(true);
    } else {
      wasMutationLoading.current = false;
      setShowLoading(false);
    }
  }, [isLoading, mutationIsLoading, isRefetching]);

  return {
    pages: data?.pages || [],
    isLoading: showLoading,
    isRefetching,
    canLoadMore: hasNextPage,
    isLoadingMore: isFetchingNextPage,
    fetchNextPage,
    isError,
    error,
  };
};
