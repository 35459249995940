import React from 'react';

import { usePatronSelector } from '../../../common/use-patron-selector';
import useScreenSize from '../../../common/use-screen-size';
import { contentSelectors } from '../../../models/content';

import ContentActionBookmark from '../content-actions/content-action-bookmark';
import ContentActionComment from '../content-actions/content-action-comment';
import ContentActionEdit from '../content-actions/content-action-edit';
import ContentActionLike from '../content-actions/content-action-like';
import ContentActionShare from '../content-actions/content-action-share';
import { AcknowledgementFooter } from './acknowledgement-footer';
import ContentActionTranslateWrapper from '../content-actions/content-action-translate-wrapper';
import { ContentActionTranslate } from '../content-actions/content-action-translate';
import ContentActionViewLikes from '../content-actions/content-action-view-likes';
import ContentActionMore from '../content-actions/content-action-more/content-action-more';
import { FlagPostAction } from '../content-actions/content-action-more/content-action-flag-post';
import { ContentActionCopyLink } from '../content-actions/content-action-more/content-action-copy-link';
import { Feature } from '../../../models/features/features';
import useFeatureFlag from '../../../common/use-feature-flag';
import { useRouteMatch } from 'react-router';
import { ApprovalFooter } from './approval-footer';

interface InfoHeaderProps {
  contentId: number;
  analyticsData: { location: string };
  emailContent: boolean;
}

export const InfoFooter: React.FC<InfoHeaderProps> = ({
  contentId,
  emailContent,
  analyticsData,
}) => {
  const canLike = usePatronSelector((state) =>
    contentSelectors.getContentCanLike(state, { contentId })
  );
  const canComment = usePatronSelector((state) =>
    contentSelectors.getContentCanComment(state, { contentId })
  );
  const canBookmark = usePatronSelector((state) =>
    contentSelectors.getContentCanBookmark(state, { contentId })
  );
  const canShare = usePatronSelector((state) =>
    contentSelectors.getContentCanShare(state, { contentId })
  );
  const canEdit = usePatronSelector((state) =>
    contentSelectors.getContentCanEdit(state, { contentId })
  );
  const canTranslate = usePatronSelector((state) =>
    contentSelectors.getContentCanTranslate(state, { contentId })
  );

  const acknowledgementActive = usePatronSelector((state) =>
    contentSelectors.getContentCanAcknowledge(state, { contentId })
  );
  const { isMobile } = useScreenSize();

  const canFlagPost = usePatronSelector((state) =>
    contentSelectors.getContentCanFlag(state, { contentId })
  );

  const tescoQ3 = useFeatureFlag(Feature.TESCO_Q3);

  const isBookmarkInMoreAction = isMobile && canBookmark;
  const isShareInMoreAction = isMobile && canShare;
  const isCopyLinkInMoreAction = isMobile;
  const isFlagPostInMoreAction = canFlagPost;

  const moreActions = [
    isBookmarkInMoreAction ? (
      <ContentActionBookmark
        contentId={contentId}
        analyticsData={analyticsData}
        showText={true}
        wideMode={true}
      />
    ) : null,
    isShareInMoreAction ? (
      <ContentActionShare
        contentId={contentId}
        analyticsData={analyticsData}
        showText={true}
        wideMode={true}
      />
    ) : null,
    isCopyLinkInMoreAction ? (
      <ContentActionCopyLink
        contentId={contentId}
        analyticsData={analyticsData}
        wideMode={true}
      />
    ) : null,
    isFlagPostInMoreAction ? (
      <FlagPostAction contentId={contentId} analyticsData={analyticsData} />
    ) : null,
  ].filter((el): el is JSX.Element => {
    return !!el;
  });

  const { path } = useRouteMatch();

  const isApproval = path.includes('approvals');

  return (
    <div className="info-footer">
      {isApproval && <ApprovalFooter contentId={contentId} />}
      {acknowledgementActive && <AcknowledgementFooter contentId={contentId} />}
      {!emailContent ? (
        <div className="action-button-toolbar">
          <div className="info-footer__actions">
            <div className="info-footer__actions--left">
              {canLike ? (
                <ContentActionLike
                  contentId={contentId}
                  analyticsData={analyticsData}
                  showText={Boolean(!isMobile || tescoQ3)}
                />
              ) : null}

              {(!isMobile || tescoQ3) && (
                <ContentActionViewLikes contentId={contentId} />
              )}
            </div>

            <div className="info-footer__actions--right">
              {canComment ? (
                <ContentActionComment
                  contentId={contentId}
                  analyticsData={analyticsData}
                  showText={!isMobile}
                  onClickAction="replace"
                />
              ) : null}

              {canEdit ? (
                <ContentActionEdit
                  contentId={contentId}
                  analyticsData={analyticsData}
                />
              ) : null}

              {canShare && !isMobile ? (
                <ContentActionShare
                  contentId={contentId}
                  analyticsData={analyticsData}
                />
              ) : null}

              {canBookmark && !isMobile ? (
                <ContentActionBookmark
                  contentId={contentId}
                  analyticsData={analyticsData}
                />
              ) : null}

              {canTranslate ? (
                <ContentActionTranslateWrapper
                  contentId={contentId}
                  analyticsData={analyticsData}
                >
                  {({ handleClick, isTranslated, contentTitle }) => (
                    <ContentActionTranslate
                      isTranslated={isTranslated}
                      handleClick={handleClick}
                      contentTitle={contentTitle}
                      showText={false}
                      wideMode={false}
                    ></ContentActionTranslate>
                  )}
                </ContentActionTranslateWrapper>
              ) : null}

              {!isMobile && (
                <ContentActionCopyLink
                  contentId={contentId}
                  analyticsData={analyticsData}
                  wideMode={false}
                />
              )}

              <ContentActionMore actions={moreActions} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
