import { useTranslation } from 'react-i18next';
import { Icon, Link } from '../../ui';

export const BackButton = ({ navigateBack }: { navigateBack: () => void }) => {
  const { t } = useTranslation();

  return (
    <Link
      className="content-detail__link--back"
      onClick={navigateBack}
      aria-label={t('common.back')}
      tabIndex={0}
      onKeyDown={(e: React.KeyboardEvent) => {
        if (['Enter', 'Spacebar', ' '].indexOf(e.key) !== -1) {
          e.stopPropagation();
          navigateBack();
        }
      }}
    >
      <Icon type="arrow_back" />
    </Link>
  );
};
