import { Actionable } from '../assistant/interfaces/Actionable';

export enum NotificationCenterTabs {
  MESSAGES = 'messages',
  ACTIVITY = 'activities',
  ARCHIVE = 'archived',
}

export type NotificationCategory =
  | 'welcome'
  | 'topic_auto_follow'
  | 'content_mention'
  | 'content_view'
  | 'content_like'
  | 'content_comment'
  | 'comment_like'
  | 'comment_mention'
  | 'comment_reply'
  | 'comment_reply_like'
  | 'push'
  | 'integration'
  | 'message'
  | 'content_critical'
  | 'content_acknowledgement'
  | 'content_approval'
  | 'poll'
  | 'content_for_you';

export type Notification = {
  id: string;
  user_id?: number;
  program_id?: number;
  category?: NotificationCategory;
  read?: boolean;
  archived?: boolean;
  created_at: string;
  updated_at?: string;
  heading: string;
  subheading?: string;
  preview?: string;
  important?: boolean;
  action_required?: boolean;
  labels: {
    important: string[];
    action_required: string[];
  };
  avatar?: {
    url: string | null;
    alt_text: string;
  };
  action?: Actionable;
};

export type NotificationCounts = {
  total_alert?: number;
  [NotificationCenterTabs.MESSAGES]?: {
    unread: number;
    important: number;
    action_required: number;
  };
  [NotificationCenterTabs.ACTIVITY]?: {
    unread: number;
  };
  [NotificationCenterTabs.ARCHIVE]?: {
    unread: number;
  };
};

export type NotificationMeta = {
  page: number;
  perPage: number;
  totalPages: number;
  totalResults: number;
  counts: NotificationCounts;
};

export type NotificationSearchMeta = {
  page: number;
  perPage: number;
  totalPages: number;
  totalResults: number;
  counts: {
    [NotificationCenterTabs.MESSAGES]?: {
      count: number;
    };
    [NotificationCenterTabs.ACTIVITY]?: {
      count: number;
    };
  };
};

export enum NotificationFilterTags {
  ACTION_REQUIRED = 'action_required',
  ARCHIVED = 'archived',
  IMPORTANT = 'important',
  READ = 'read',
  UNARCHIVED = 'unarchived',
  UNREAD = 'unread',
}

export const SEARCH_RESULT_COUNT_TAG = 'search_result_count';
export type NotificationTags =
  | NotificationFilterTags
  | typeof SEARCH_RESULT_COUNT_TAG;

export enum NotificationFeedTypes {
  MESSAGES = 'message',
  ACTIVITY = 'activity',
  ARCHIVED = 'archived',
}

export type FeedPageData = {
  data: Notification[];
  meta: NotificationMeta;
};

export type SearchFeedPageData = {
  data: Notification[];
  meta: NotificationSearchMeta;
};

export enum BulkEditAction {
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  MARK_AS_READ = 'mark_as_read',
  MARK_AS_UNREAD = 'mark_as_unread',
  OPEN = 'open',
}
