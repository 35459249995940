import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { trackAssistantInboxLoad } from '../../models/assistant/analytics';
import useAssistantCommand from '../../common/use-assistant-command';

import { AssistantCommandSelector } from '../../components/assistant';
import { Feature, getFeatureFlag } from '../../models/features/features';
import { AssistantError } from './assistant-error';
import { useTranslation } from 'react-i18next';
import { getProfileName } from '../../models/profile/selectors';
import { RootPatronState } from '../../common/use-patron-selector';
import AssistantIntegrationsList from './assistant-integrations-list';
import { AssistantWelcomeMessage } from './assistant-welcome-message';
import styles from './assistant.module.scss';

const MaintenanceMessageKey = 'assistant.maintenance_message';

export function AssistantHome() {
  const { runCommandInAssistant } = useAssistantCommand();

  const { t } = useTranslation();
  const statusRef = useRef<HTMLDivElement>(null);

  const maintenanceMessage = useSelector((state: RootPatronState) => {
    const message = getFeatureFlag(state, Feature.INBOX_MAINTENANCE) as string;
    if (!message) {
      return undefined;
    }

    const name = getProfileName(state);

    return name ? t(MaintenanceMessageKey, { name, message }) : message;
  });

  useEffect(() => {
    trackAssistantInboxLoad();
  }, []);

  useEffect(() => {
    // Easier for user to navigate to the main content when they first reach this page
    statusRef.current?.focus();
  }, []);

  return maintenanceMessage ? (
    <AssistantError error={maintenanceMessage} />
  ) : (
    <section
      className={styles.assistantLanding}
      aria-label={t('assistant.assistant_navigation.landing')}
    >
      <div className={styles.body}>
        <AssistantWelcomeMessage statusRef={statusRef} />
        <div className={styles.leftPanel}></div>
        <div className={styles.messages} ref={statusRef}>
          <AssistantIntegrationsList />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.inputContainer}>
          <AssistantCommandSelector
            onSelect={(c) => {
              runCommandInAssistant(c, {
                location: 'inbox',
                ...c.tracking_context,
              });
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default AssistantHome;
