const ACKNOWLEDGE = 'patron/contents/ACKNOWLEDGE';
const ACKNOWLEDGE_COMPLETE = 'patron/contents/ACKNOWLEDGE_COMPLETE';
const UNACKNOWLEDGE = 'patron/contents/UNACKNOWLEDGE';
const COMPLETED = 'patron/contents/COMPLETED';
const ADD = 'patron/contents/ADD';
const ADD_BULK = 'patron/contents/ADD_BULK';
const LIKE = 'patron/contents/LIKE';
const UNLIKE = 'patron/contents/UNLIKE';
const BOOKMARK = 'patron/contents/BOOKMARK';
const UNBOOKMARK = 'patron/contents/UNBOOKMARK';
const SHARE = 'patron/contents/SHARE';
const UNSHARE = 'patron/contents/UNSHARE';
const INCREMENT_COMMENT_COUNT = 'patron/contents/INCREMENT_COMMENT_COUNT';
const DECREMENT_COMMENT_COUNT = 'patron/contents/DECREMENT_COMMENT_COUNT';
const UPDATE = 'patron/contents/UPDATE';

export default {
  ACKNOWLEDGE,
  ACKNOWLEDGE_COMPLETE,
  UNACKNOWLEDGE,
  COMPLETED,
  ADD,
  ADD_BULK,
  LIKE,
  UNLIKE,
  BOOKMARK,
  UNBOOKMARK,
  SHARE,
  UNSHARE,
  INCREMENT_COMMENT_COUNT,
  DECREMENT_COMMENT_COUNT,
  UPDATE,
};
