import { MouseEvent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from './context';
import {
  NotificationCenterTabs,
  NotificationFilterTags,
  NotificationTags,
  SEARCH_RESULT_COUNT_TAG,
} from '../../models/notifications/types';
import { mapFilterTagToIcon } from './toolbar/filter-dropdown';
import {
  Tag,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
} from '@socialchorus/shared-ui-components';

export const mapTagsToVariant = (tag: NotificationTags) => {
  switch (tag) {
    case NotificationFilterTags.ACTION_REQUIRED:
      return 'warning';
    case NotificationFilterTags.IMPORTANT:
      return 'negative';
    case NotificationFilterTags.READ:
      return 'default';
    case NotificationFilterTags.UNREAD:
      return 'default';
    case NotificationFilterTags.ARCHIVED:
      return 'default';
    case NotificationFilterTags.UNARCHIVED:
      return 'default';
    case SEARCH_RESULT_COUNT_TAG:
      return 'default';
  }
};

export const CountTag = ({
  count,
  tab,
  type,
  container,
}: {
  count?: number;
  tab: NotificationCenterTabs;
  type: NotificationTags;
  container: HTMLDivElement | null | undefined;
}) => {
  const { t } = useTranslation();

  const {
    tabs: {
      activeTab: [activeTab],
    },
    filterTags: [, setFilterTags],
  } = useContext(NotificationContext);

  const active = activeTab === tab;

  const handleTabTagClick = useCallback(
    (tag: NotificationFilterTags) =>
      active
        ? (e: MouseEvent) => {
            e.stopPropagation();
            setFilterTags([tag]);
          }
        : undefined,
    [active, setFilterTags]
  );

  //show 0 for search result count
  const show =
    (type === SEARCH_RESULT_COUNT_TAG && count !== undefined) || !!count;

  if (!show) return null;

  const tag = (
    <Tag
      variant={mapTagsToVariant(type)}
      icon={
        type !== SEARCH_RESULT_COUNT_TAG
          ? mapFilterTagToIcon({
              tag: type,
              compact: true,
            })
          : undefined
      }
      label={`${count >= 10 ? '9+' : count}`}
      compact
      onClick={
        type !== SEARCH_RESULT_COUNT_TAG ? handleTabTagClick(type) : undefined
      }
    />
  );

  //there seems to be an issue with disabling tooltips with an asChild TooltipTrigger when combined with Tag, so we just return the tag if the tab is not active.
  return active && type !== SEARCH_RESULT_COUNT_TAG ? (
    <TooltipRoot>
      <TooltipContent
        description={t(`notification_center.tags.${type}`)}
        sideOffset={5}
        container={container}
      />
      <TooltipTrigger asChild>{tag}</TooltipTrigger>
    </TooltipRoot>
  ) : (
    tag
  );
};
